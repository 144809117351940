"use client";

import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { FiInfo } from "react-icons/fi";
import richTextParser, { RichText } from "utils/richTextParser";

export default function TooltipPopover({
  content,
  contentType,
}: { content: string; contentType: "plainText" } | { content: unknown; contentType: "richText" }) {
  const [openPopover, setOpenPopover] = useState(false);
  function onOpenChange(open: boolean) {
    setOpenPopover(open);
  }
  return (
    <Popover.Root open={openPopover} onOpenChange={onOpenChange}>
      <Popover.Trigger onMouseEnter={() => onOpenChange(true)} onMouseLeave={() => onOpenChange(false)} asChild>
        <button className="ml-[5px] mt-[2px] flex align-middle">
          <FiInfo className="h-2xs w-2xs lg:h-xs lg:w-xs" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          hideWhenDetached={true}
          collisionPadding={20}
          className="relative z-30 rounded-xl bg-primary-dark-900 px-xs py-2xs text-primary-neutral-50 [&_p]:mb-0 [&_p]:text-2xs"
          sideOffset={5}
          side="top"
        >
          {contentType === "plainText" ? content : richTextParser(content as RichText) || ""}
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
