import ListItemIconText from "components/ListItem/ListItemIconText";
import typography from "components/typography";
import { cn } from "utils/functions";

type LowCodeCommercialSaaSTableContent = {
  elementType: string;
  elementTitle: string;
  elementContent: {
    type: string;
    content: {
      icon?: string;
      label: string;
    }[];
  }[];
};
export default function LowCodeCommercialSaaSTable({ json }: { json: LowCodeCommercialSaaSTableContent }) {
  return (
    <div className="flex flex-col gap-md pb-md">
      <div className="">
        <h2 className={cn(typography.heading.innerSubheading, "text-left")}>{json.elementTitle}</h2>
      </div>
      <div className="md:rounded-lg md:p-lg md:shadow-pink">
        <div className="relative grid gap-xs md:gap-sm">
          {json.elementContent.map((item, index) => {
            if (item.type === "header") {
              return (
                <div
                  className="relative grid grid-cols-2 border-b gap-xs border-tertiary-pink-500/20"
                  key={`LowCodeCommercialSaaSTable-${index}`}
                >
                  {item.content.map((content, ind) => {
                    return (
                      <div
                        className="pb-3xs"
                        key={`LowCodeCommercialSaaSTable-${ind}`}
                      >
                        <h3 className={cn(typography.heading.innerSubheading, "text-tertiary-pink-500")}>
                          {content.label}
                        </h3>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div
                className="relative grid grid-cols-2 gap-xs border-b border-tertiary-pink-500/20"
                key={`LowCodeCommercialSaaSTable-${index}`}
              >
                {item.content.map((content, ind) => {
                  return (
                    <div key={`item-${ind}-${ind}`} className="pb-3xs">
                      <ListItemIconText
                        icon={content.icon || ""}
                        text={content.label}
                        clName="flex items-baseline gap-3"
                        textClass="text-base font-light"
                        iconClass="w-6 h-6"
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
