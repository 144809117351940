import typography from "components/typography";
import { cn } from "utils/functions";

type LowCodeWhatWorksWhenTableProps = {
  elementContent: {
    content: {
      label: string;
      highlight?: boolean;
      width: number;
    }[];
    type: string;
  }[];
};

export default function LowCodeWhatWorksWhenTable({ json }: { json: LowCodeWhatWorksWhenTableProps }) {
  return (
    <div className="flex flex-col gap-sm">
      <div className="overflow-x-auto">
        <div className="rounded-lg border-2 border-primary-dark-500 min-w-[500px]">
          {json.elementContent.map((item, index) => {
            const { type } = item;
            return (
              <div className={cn("grid grid-cols-4 md:grid-cols-12 border-b-primary-dark-500",
                index!==json.elementContent.length-1 ? "border-b" : ""
              )} key={index}>
                {item.content.map((data, ind) => {
                  const { width } = data;
                  return (
                    <div
                      key={ind}
                      className={cn(
                        `md:col-span-${width} col-span-1`,
                        data.highlight ? "md:bg-primary-neutral-50/[0.08]" : "",
                        typography.body.sm,
                        type === "header" ? "" : "font-light",
                        "flex items-center border-r-primary-dark-500 p-xs",
                        ind === 0 ? "justify-center max-md:hidden" : "",
                        ind === item.content.length - 1 ? "" : " border-r",
                        (index % 2) === 0 ? "max-md:bg-primary-neutral-50/[0.08]" : "",
                      )}
                    >
                      <span className="inline-block align-middle">{data.label}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
