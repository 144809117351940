"use client";

import clsx from "clsx";
import CtaLink from "components/CtaLink";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import ArrowTopRight_12px from "icons/ArrowTopRight_12px";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { cn } from "utils/functions";

export interface HomepageShowcaseConfigType {
  viewMoreLink: ViewMoreLink;
  autoplay: boolean;
  autoplaySpeed: number;
  useCases: UseCase[];
}

interface ViewMoreLink {
  label: string;
  href: string;
  hasIcon: boolean;
}

interface UseCase {
  label: string;
  imageAssetReferenceId: string;
}

export default function HomepageShowcase({
  json,
  assets,
}: {
  json: HomepageShowcaseConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const { viewMoreLink, autoplay, autoplaySpeed, useCases } = json;
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    { align: "start" },
    autoplay ? [Autoplay({ delay: autoplaySpeed, stopOnInteraction: true })] : [],
  );
  const [emblaHeadingRef, emblaHeadingApi] = useEmblaCarousel({
    dragFree: true,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onHeadingClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaHeadingApi) return;
      emblaMainApi.scrollTo(index);
      emblaMainApi.plugins().autoplay?.stop();
    },
    [emblaMainApi, emblaHeadingApi],
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaHeadingApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaHeadingApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaHeadingApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <div>
      <div className="mb-sm hidden grid-cols-12 gap-sm rounded-md bg-primary-dark-100/50 p-3xs lg:grid">
        <div className="relative col-span-10 grid auto-cols-fr grid-flow-col gap-sm text-center">
          {useCases.map((useCase, index) => {
            return (
              <div
                className={clsx(
                  typography.label,
                  "relative z-10 cursor-pointer rounded-md py-xs font-display text-sm transition-colors duration-300 xl:text-sm",
                  selectedIndex === index && "font-bold text-primary-neutral-50 delay-150",
                  selectedIndex !== index && "hover:bg-primary-dark-900/10",
                )}
                aria-label={useCase.label}
                key={useCase.label + "-tab-" + index}
                onClick={() => onHeadingClick(index)}
              >
                {useCase.label}
              </div>
            );
          })}
          <div
            className="absolute left-0 top-0 h-full w-[calc((100%-80px)/5)] rounded-md bg-primary-dark-900 transition-transform duration-300 ease-in-out will-change-transform"
            style={{
              transform: `translateX(calc(${selectedIndex} * 100% + (${selectedIndex * 20}px)))`,
            }}
          ></div>
        </div>
        <div className="relative col-span-2 flex items-center justify-center text-center">
          <div className="absolute left-0 top-1/2 h-4/5 -translate-x-[10px] -translate-y-1/2 border-r border-r-primary-dark-900/30"></div>
          <CtaLink
            label={viewMoreLink.label}
            link={viewMoreLink.href}
            variant="Link"
            size="Small"
            endIcon={viewMoreLink.hasIcon ? "ArrowTopRight" : undefined}
          />
        </div>
      </div>
      <div className="mb-sm rounded bg-primary-dark-100/50 p-3xs lg:hidden">
        <div className="overflow-hidden" ref={emblaHeadingRef}>
          <div className="flex space-x-sm">
            {useCases.map((useCase, index) => (
              <div className="relative flex-[0_0_auto]" key={useCase.label}>
                <button
                  className={clsx(
                    typography.label,
                    "rounded px-sm py-xs transition-colors",
                    selectedIndex === index ? "bg-primary-dark-900 text-primary-neutral-50" : "",
                  )}
                  onClick={() => onHeadingClick(index)}
                >
                  {useCase.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-screen-md overflow-hidden xl:max-w-screen-lg" ref={emblaMainRef}>
        <div className="flex space-x-sm">
          {useCases.map((useCase, index) => {
            const asset = assets?.find((asset) => asset?.sys.id === useCase.imageAssetReferenceId);
            return (
              <Image
                key={useCase.label + "-content-" + index}
                priority={index === 0}
                className={cn(
                  "relative h-auto w-full min-w-0 flex-[0_0_100%] object-contain object-top transition-opacity duration-1000",
                  selectedIndex === index && "opacity-100",
                  selectedIndex !== index && "opacity-0",
                )}
                src={asset?.url || ""}
                alt={asset?.title || "Showcase image"}
                width={asset?.width || 0}
                height={asset?.height || 0}
                sizes="
                (min-width: 1280px) 1024px, 768px
                "
              />
            );
          })}
        </div>
      </div>
      <Hyperlink
        className={clsx(
          typography.label,
          "mt-sm flex h-full items-center justify-center gap-[5px] underline transition-colors hover:text-primary-dark-500 lg:hidden",
        )}
        href={viewMoreLink.href}
      >
        {viewMoreLink.label}
        <span className="h-[12px] w-[12px]">
          <ArrowTopRight_12px />
        </span>
      </Hyperlink>
    </div>
  );
}
