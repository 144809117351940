import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { ComponentCellFragment } from "contentful/gql/graphql";
import { useState } from "react";
import { FiInfo, FiCheck, FiMinus } from "react-icons/fi";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

function Cell({
  cell,
  cellIdx,
  isLastRow,
}: {
  cell?: ComponentCellFragment | undefined | null;
  cellIdx: number;
  isLastRow: boolean;
}) {
  const [openPopover, setOpenPopover] = useState(false);
  function onOpenChange(open: boolean) {
    setOpenPopover(open);
  }
  return (
    <div
      className={cn(
        "h-[68px] w-full overflow-clip bg-primary-neutral-50 pr-4xs lg:h-full lg:py-3xs lg:pr-0",
        cellIdx == 1 || cellIdx == 2
          ? isLastRow
            ? "rounded-b-lg border-x border-b border-primary-dark-100 pb-xs lg:rounded-b-xl"
            : "border-x border-primary-dark-100"
          : "",
        cellIdx == 3
          ? isLastRow
            ? "rounded-b-lg border-x border-b border-primary-light-500 pb-xs lg:rounded-b-xl"
            : "border-x border-primary-light-500"
          : "",
      )}
    >
      <div className={cn("flex items-center text-xs lg:text-sm xl:text-base", cellIdx !== 0 ? "px-xs" : "font-medium")}>
        {cell?.heading && (
          <>
            {cell.heading}

            {cell?.tooltipText && (
              <Popover.Root open={openPopover} onOpenChange={onOpenChange}>
                <Popover.Trigger
                  onMouseEnter={() => onOpenChange(true)}
                  onMouseLeave={() => onOpenChange(false)}
                  asChild
                >
                  <button className="ml-[5px] mt-[2px] flex align-middle">
                    <FiInfo className="h-2xs w-2xs lg:h-xs lg:w-xs" />
                  </button>
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content
                    hideWhenDetached={true}
                    collisionPadding={20}
                    className="relative z-30 rounded-xl bg-primary-dark-900 px-xs py-2xs text-primary-neutral-50 [&_p]:mb-0 [&_p]:text-2xs"
                    sideOffset={5}
                    side="top"
                  >
                    {richTextParser(cell.tooltipText as RichText) || ""}
                    <Popover.Arrow className="PopoverArrow" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            )}
          </>
        )}
        {cell?.icon && cell.icon === "Checkmark" && (
          <FiCheck
            strokeWidth={1.5}
            className={clsx("h-5 w-5 rounded-full bg-primary-light-500/10 p-4xs text-primary-light-500 lg:h-6 lg:w-6")}
            aria-hidden="true"
          />
        )}
        {cell?.icon && cell.icon !== "Checkmark" && (
          <FiMinus
            className={clsx("h-5 w-5 rounded-full p-4xs text-primary-light-500 lg:h-6 lg:w-6")}
            aria-hidden="true"
          />
        )}
      </div>

      <div className="[&_p]:!text-sm">{cell?.body && richTextParser(cell.body as RichText)}</div>
    </div>
  );
}

export default Cell;
