"use client";

import { useSearchParams } from "next/navigation";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import BlogCard from "components/Cards/BlogCard/BlogCard";
import BlogHero from "./BlogHero";
import { ComponentCustomJsonFragment, TemplateBlogPostListingFragment } from "contentful/gql/graphql";
import { useRouter } from "next/navigation";
import { cn } from "utils/functions";
import SidebarConversionPanel from "components/ComponentCustomJSON/SidebarConversionPanel";
import NewsletterSignupPanel from "components/ComponentCustomJSON/NewletterSignupPanel";

const BlogListing = (props: {
  blogPosts: (TemplateBlogPostListingFragment | null)[];
  sidebar: ComponentCustomJsonFragment;
}) => {
  const { blogPosts } = props;
  const [filter, setFilter] = useState<string>("All");
  const [filteredblogPosts, setFilteredblogPosts] = useState(blogPosts);
  const [page, setPage] = useState<number>(1);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const itemsPerPage = 16;

  const categories: string[] = [];

  const featuredblogPosts = blogPosts && blogPosts.filter((blog) => blog?.featuredBlog);
  const featuredSorted = featuredblogPosts.sort(
    (a, b) => new Date(b?.publishDate).getTime() - new Date(a?.publishDate).getTime(),
  );
  const featuredItem = featuredSorted[0] || blogPosts[0];

  blogPosts.map((blog) => {
    if (blog?.category && !categories.includes(blog.category)) {
      categories.push(blog.category);
    }
    return;
  });
  categories.sort();
  categories.reverse();
  categories.push("All");
  categories.reverse();

  useEffect(() => {
    if (filter !== "All") {
      const modifiedBlogListByFilter = blogPosts.filter((blog) => blog?.category === filter);
      setFilteredblogPosts(modifiedBlogListByFilter);
    } else {
      setFilteredblogPosts(blogPosts);
    }
  }, [filter, blogPosts]);

  const searchParams = useSearchParams();

  useEffect(() => {
    const cat = (searchParams.get("cat") || "") as string;
    if (cat) {
      setFilter(cat);
    }
    const page = (searchParams.get("page") || "") as string;
    if (page) {
      setPage(parseInt(page));
    }
  }, [searchParams]);

  return (
    <div>
      {featuredItem && <BlogHero {...featuredItem} />}
      <div className="gap-sm lg:grid lg:grid-cols-12">
        <aside className="lg:col-span-3 xl:col-span-3">
          <h2 className="sr-only">Filters</h2>
          <div className="sticky top-3xl hidden lg:block">
            <Filters categories={categories} filter={filter} setFilter={setFilter} page={page} />
            <SidebarConversionPanel {...props.sidebar} />
          </div>
        </aside>

        <div className="lg:col-span-9 xl:col-span-9" ref={wrapper}>
          <div className="mx-auto grid gap-sm sm:grid-cols-2">
            <div className="row-start-3 sm:col-span-2">
              <NewsletterSignupPanel />
            </div>
            {filteredblogPosts &&
              filteredblogPosts
                .filter((blog) => blog?.sys.id !== featuredItem?.sys.id)
                .slice(0, page * itemsPerPage)
                .map((blog) => blog && <BlogCard blog={blog} key={blog?.sys.id} />)}
          </div>
          {page * itemsPerPage < filteredblogPosts.length && (
            <div className="text-center">
              <button
                className="mx-auto mt-md rounded border border-primary-light-500 bg-primary-light-500 px-sm py-2xs font-medium text-primary-neutral-50 transition-colors hover:bg-primary-light-500/20 hover:text-primary-light-500"
                onClick={() => {
                  let nextPage = page + 1;
                  setPage(nextPage);
                  router.replace(
                    `?${filter !== "" ? "cat=" + filter + "&" : ""}${nextPage > 1 ? "page=" + nextPage : ""}`,
                    {
                      scroll: false,
                    },
                  );
                }}
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogListing;

function Filters({
  categories,
  filter,
  setFilter,
  page,
}: {
  categories: string[];
  filter: string;
  setFilter: any;
  page: number;
}) {
  const router = useRouter();

  return (
    <fieldset className="space-y-4xs overflow-hidden rounded-lg bg-primary-neutral-100 py-sm">
      {categories.map((option, optionIdx) => (
        <div
          key={option}
          className="relative flex items-center transition-shadow duration-500"
          style={
            filter === option
              ? {
                  boxShadow: "inset 1px 0px #7441F7",
                  WebkitBoxShadow: "inset 1px 0px #7441F7",
                }
              : {
                  boxShadow: "unset",
                  WebkitBoxShadow: "unset",
                }
          }
        >
          <input
            id={option}
            checked={filter === option}
            type="radio"
            className="hidden"
            onChange={() => {
              setFilter(option);
              router.replace(`?${filter !== "All" ? "cat=" + option : ""}${page > 1 ? "&page=" + page : ""}`, {
                scroll: false,
              });
            }}
          />
          <label htmlFor={option} className={cn("relative z-10 w-full px-md py-4xs text-base hover:cursor-pointer")}>
            {option}
          </label>
          <div
            className={cn(
              "absolute left-0 top-0 z-0 h-full w-full transition duration-700 will-change-transform",
              filter === option ? "translate-x-0" : "-translate-x-full",
            )}
            style={{
              background: "linear-gradient(90deg, rgba(116, 65, 247, 0.10) 0.11%, rgba(116, 65, 247, 0.00) 80.96%)",
            }}
          ></div>
        </div>
      ))}
    </fieldset>
  );
}
