'use client';

import UseCaseCard from 'components/Cards/UseCaseCard/UseCaseCard';
import { TemplateUseCaseListingFragment } from 'contentful/gql/graphql';

import type { FC } from 'react';

interface UseCaseListingProps {
  useCases: (TemplateUseCaseListingFragment | null)[];
}

const UseCaseListing = ({ useCases }: UseCaseListingProps) => (
  <div className="grid grid-cols-1 gap-sm sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {useCases && useCases.map(useCase => useCase && <UseCaseCard key={useCase.sys.id} useCase={useCase} />)}
  </div>
);

export default UseCaseListing;
