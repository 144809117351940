"use client";

import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { cva } from "class-variance-authority";
import CtaLink from "components/CtaLink";
import Icon from "components/Icon";
import { componentCellFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import {
  ComponentCellFragment,
  ComponentHeadingFragment,
  ComponentPricingComparisonTableFragment,
  ComponentRowFragment,
} from "contentful/gql/graphql";
import { useState } from "react";
import { cn } from "utils/functions";
import Cell from "./Cell";

const cellStyles = cva([""], {
  variants: {
    type: {
      normal: "border-primary-dark-100",
      highlighted: "border-primary-light-500",
    },
    location: {
      first: "rounded-t-lg border-x border-t lg:rounded-t-xl",
      middle: "border-x",
      last: "rounded-b-lg border-x border-b lg:rounded-b-xl",
    },
  },
});

interface PricingComparisonsTableProps extends ComponentPricingComparisonTableFragment {
  heading: ComponentHeadingFragment;
  headerRow: (ComponentCellFragment | null | undefined)[] | undefined;
  pricingRow: (ComponentCellFragment | null | undefined)[] | undefined;
  bodyRowCollection: (ComponentRowFragment | null | undefined)[] | undefined;
}

const PricingComparisonsTable = (props: PricingComparisonsTableProps) => {
  const headerRow = props.headerRow;
  const pricingRow = props.pricingRow;
  const bodyRowCollection = props.bodyRowCollection;
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [openPlanSelect, setOpenPlanSelect] = useState(false);

  return (
    <div>
      <div className="sticky top-2xl z-10 grid grid-cols-2 overflow-hidden bg-primary-neutral-50">
        <div className="pr-2xs pt-md">
          <Dialog.Root open={openPlanSelect} onOpenChange={setOpenPlanSelect}>
            <Dialog.Trigger asChild>
              <button className="mt-xs flex items-center font-display text-sm font-bold text-primary-dark-500">
                Change plan
                <span className="inline-block h-md w-md p-3xs">
                  <Icon url="/assets/icons/opposite-directions-glyph-16.svg" />
                </span>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="fixed inset-0 z-30 bg-primary-dark-900/10" />
              <Dialog.Content className="fixed left-1/2 top-1/2 z-30 w-5/6 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-primary-neutral-50 p-sm">
                <VisuallyHidden.Root asChild>
                  <Dialog.Title>Change plan</Dialog.Title>
                </VisuallyHidden.Root>
                <Dialog.Description className="">Select a plan to see the available features.</Dialog.Description>
                <div className="w-full divide-y">
                  <button
                    onClick={() => {
                      setSelectedIndex(0);
                      setOpenPlanSelect(false);
                    }}
                    className="flex w-full items-center py-xs text-left text-lg"
                  >
                    Free
                  </button>
                  <button
                    onClick={() => {
                      setSelectedIndex(1);
                      setOpenPlanSelect(false);
                    }}
                    className="flex w-full items-center py-xs text-left text-lg"
                  >
                    Business
                  </button>
                  <button
                    onClick={() => {
                      setSelectedIndex(2);
                      setOpenPlanSelect(false);
                    }}
                    className="flex w-full items-center py-xs text-left text-lg"
                  >
                    Enterprise
                  </button>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          {(() => {
            const cell = headerRow?.[selectedIndex + 1];
            const cellIdx = selectedIndex + 1;
            return (
              <div key={cell?.sys.id} className={cn("bg-primary-neutral-50 pt-md")}>
                <div
                  className={cn(
                    "flex h-full w-full origin-top flex-col border-b border-b-primary-neutral-50 px-xs py-xs transition-transform",
                    cellIdx !== 0 ? cellStyles({ type: "normal", location: "first" }) : "",
                    cellIdx === 3 ? cellStyles({ type: "highlighted", location: "first" }) : "",
                  )}
                >
                  <span className="sr-only">Feature by</span>
                  <div
                    className={cn("w-full text-left text-lg font-bold lg:text-2xl xl:text-3xl", {
                      "text-secondary-brand-500": cellIdx == 2,
                      "bg-gradient-to-br from-primary-light-500 to-primary-light-300": cellIdx == 3,
                    })}
                    style={
                      cellIdx == 3
                        ? {
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            color: "transparent",
                          }
                        : undefined
                    }
                  >
                    {cell?.heading}
                  </div>
                  {pricingRow &&
                    (() => {
                      const cell = pricingRow[cellIdx];
                      if (!cell) {
                        return null;
                      }
                      const ctas = cell?.callToActionsCollection?.items.map((cta) => getFragmentData(ctaFragment, cta));
                      return (
                        <>
                          <div className={cn("w-full text-left text-md font-normal xl:text-lg")}>{cell?.heading}</div>

                          {ctas && (
                            <div className="mt-auto flex flex-col space-y-3xs pt-xs lg:flex-row lg:space-x-3xs">
                              {ctas.map((cta, ctaIdx) => {
                                return (
                                  <CtaLink
                                    key={cta?.sys.id || "" + cellIdx + ctaIdx}
                                    {...cta}
                                    extendClassNames="w-full"
                                    textAlign="center"
                                    size={"Small"}
                                    variant={
                                      cellIdx === 1
                                        ? ctaIdx == 0
                                          ? "free"
                                          : "free_secondary"
                                        : cellIdx === 2
                                        ? "business"
                                        : cellIdx === 3
                                        ? "enterprise"
                                        : undefined
                                    }
                                  />
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })()}
                </div>
              </div>
            );
          })()}
        </div>
      </div>
      <div className="flex">
        <div className="relative w-1/2 flex-shrink-0">
          {bodyRowCollection &&
            bodyRowCollection.map((rowFragment, rowIdx) => {
              const row = {
                ...rowFragment,
                cellCollection: rowFragment?.cellCollection?.items.map((cell) =>
                  getFragmentData(componentCellFragment, cell),
                ),
              };
              if (!!row.cellCollection?.length && row.cellCollection?.length > 1) {
                return row.cellCollection?.slice(0, 1).map((cell, cellIdx) => {
                  if (!cell) {
                    return null;
                  }
                  return (
                    <Cell
                      key={rowIdx + "-" + row.sys?.id + cellIdx + "-" + cell.sys.id}
                      cell={cell}
                      cellIdx={cellIdx}
                      isLastRow={rowIdx === bodyRowCollection.length - 1}
                    />
                  );
                });
              } else {
                return (
                  <div
                    key={row?.cellCollection?.[0]?.sys.id}
                    className="sticky top-[200px] z-20 bg-primary-neutral-50 p-0 text-left"
                  >
                    <div className="pb-md pt-md text-sm font-medium text-primary-light-500 xl:text-xl">
                      {row?.cellCollection?.[0]?.heading}
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <div className="relative">
          {bodyRowCollection &&
            bodyRowCollection.map((rowFragment, rowIdx) => {
              const row = {
                ...rowFragment,
                cellCollection: rowFragment?.cellCollection?.items
                  .slice(1)
                  .map((cell) => getFragmentData(componentCellFragment, cell)),
              };
              return (
                <div key={row.sys?.id} className="">
                  {!!row.cellCollection?.length &&
                  row.cellCollection[selectedIndex] &&
                  row.cellCollection?.length > 1 ? (
                    <Cell
                      cellIdx={selectedIndex + 1}
                      cell={row.cellCollection[selectedIndex]}
                      isLastRow={rowIdx === bodyRowCollection.length - 1}
                    />
                  ) : (
                    <div className="sticky top-[200px] h-[88px] bg-primary-neutral-50 py-0">
                      <div
                        className={cn(
                          "flex h-full w-full border-x pb-2xs pt-xs",
                          selectedIndex === 2 ? "border-primary-light-500" : "border-primary-dark-100",
                        )}
                      >
                        <hr className="mx-xs my-auto w-full border-primary-dark-500/30" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PricingComparisonsTable;
