import { ComponentCardFragment } from "contentful/gql/graphql";
import HorizontalBasicCard from "./BasicCardHorizontal";
import StackedBasicCard from "./BasicCardStacked";

const BasicCard = (card: ComponentCardFragment) => {
  if (card.layout?.toLowerCase() === "stacked") {
    return <StackedBasicCard {...card} />;
  } else {
    return <HorizontalBasicCard {...card} />;
  }
};

export default BasicCard;
