import { cn } from "utils/functions";
import typography from "components/typography";
import Link from "next/link";
import Icon from "components/Icon";
import ListItemIconText from "components/ListItem/ListItemIconText";

type LowCodeMythTruthCompareProps = {
  type: string;
  content: {
    label: string;
    icon: string;
  }[];
  linkTitle?: string;
  linkUrl?: string;
};
export default function LowCodeMythTruthCompare({
  json,
}: {
  json: {
    elementType: string;
    elementContent: LowCodeMythTruthCompareProps[];
  };
}) {
  return (
    <div className="flex flex-col gap-sm">
      {json.elementContent.map((item, index) => {
        const { type } = item;
        return (
          <div
            className={cn("relative", type === "row" ? "rounded-lg border border-primary-dark-500/50" : "")}
            key={`LowCodeMythTruthCompare-${index}`}
          >
            <div className={cn("grid grid-cols-2 gap-sm", type === "header" ? "" : "p-sm")}>
              {item.content.map((data, ind) => {
                if (type === "header") {
                  return (
                    <h4
                      className={cn(typography.heading.innerSubheading)}
                      key={`LowCodeMythTruthCompare-${index}-${ind}`}
                    >
                      {data.label}
                    </h4>
                  );
                }
                return (
                  <div className="flex gap-sm" key={`LowCodeMythTruthCompare-${index}-${ind}`}>
                    <ListItemIconText
                      icon={data.icon}
                      text={data.label}
                      clName="flex gap-2xs"
                      textClass={cn("text-primary-dark-900", typography.body.sm)}
                      iconClass="text-tertiary-pink-500"
                    />
                  </div>
                );
              })}
            </div>
            {item.linkTitle && (
              <div className="relative w-full rounded-b-lg bg-tertiary-pink-50 py-3xs text-center">
                <Link
                  href={item.linkUrl ?? ""}
                  className={cn(
                    typography.body.xs,
                    "flex items-center justify-center gap-3xs font-light text-primary-dark-900 text-tertiary-pink-500 px-sm",
                  )}
                >
                  {item.linkTitle}
                  <span className="h-2xs w-2xs md:h-3xs md:w-3xs">
                    <Icon url="/assets/icons/arrow.svg" />
                  </span>
                </Link>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
