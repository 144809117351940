'use client';

import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ColorizedText = (props: { heading: string; pinnedContainer?: any }) => {
  const tl = useRef<any>(null);
  const ctx = useRef<any>(null);
  const sectionRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const gradientRef = useRef<any>(null);

  const colorizedArray = props.heading.split(/\{%|%\}/).map((segment, index) => {
    if (index % 2 === 1) {
      return (
        <span
          key={segment}
          className="relative inline-block text-tertiary-pink-500"
        >
          {segment}
        </span>
      );
    }

    return (
      <span
        key={segment}
        dangerouslySetInnerHTML={{
          __html: segment,
        }}
      ></span>
    );
  });


  return <span ref={sectionRef}>{colorizedArray}</span>;
};

export default ColorizedText;
