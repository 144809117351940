'use client';

import { cva } from "class-variance-authority";
import typography from "components/typography";
import { ComponentRichTextFragment } from 'contentful/gql/graphql';
import { FC } from 'react';
import richTextParser, { RichText } from 'utils/richTextParser';

const RichTextComponent: FC<ComponentRichTextFragment> = ({ body, textAlign }) => {
  const richTextClasses = cva([typography.body.cardBody], {
    variants: {
      textAlign: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
    },
  });
  return (
    <div className={richTextClasses({ textAlign: textAlign?.toLowerCase() as "left" | "center" | "right" })}>
      {richTextParser(body as RichText)}
    </div>
  );
};

export default RichTextComponent;
