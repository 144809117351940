import React from 'react';
import { IconProps } from './IconProps';

function FileJS_32px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'FileJS 32px';

  return (
    <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokewidth}
      >
        <polyline fill="none" points="20,1 20,9 28,9 " stroke={fill} />
        <polyline fill="none" points="28,13 28,9 20,1 4,1 4,13 " stroke={fill} />
        <polyline fill="none" points="4,27 4,31 28,31 28,27 " stroke={fill} />
        <path
          d="M12.5,24.8c-0.3,0-0.5,0-0.8-0.1v-1.2c0.1,0,0.2,0,0.3,0.1s0.2,0,0.3,0 c0.3,0,0.4-0.1,0.5-0.2s0.2-0.4,0.2-0.8v-5.3h1.6v5.2c0,0.8-0.2,1.4-0.5,1.8S13.1,24.8,12.5,24.8z"
          stroke="none"
          strokeLinejoin="miter"
        />
        <path
          d="M19.5,21.3c0,0.4-0.1,0.7-0.3,0.9s-0.4,0.5-0.8,0.6s-0.7,0.2-1.2,0.2 c-0.4,0-0.7,0-1-0.1s-0.5-0.1-0.8-0.3v-1.4c0.3,0.2,0.6,0.3,0.9,0.4s0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.2-0.3 c0-0.1,0-0.1-0.1-0.2s-0.1-0.1-0.2-0.2s-0.3-0.2-0.8-0.4c-0.4-0.2-0.7-0.3-0.8-0.5s-0.3-0.3-0.4-0.5s-0.1-0.4-0.1-0.7 c0-0.5,0.2-0.9,0.6-1.2s0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4L19,18.8c-0.5-0.2-1-0.4-1.4-0.4c-0.2,0-0.3,0-0.4,0.1 S17,18.7,17,18.8c0,0.1,0.1,0.2,0.2,0.3s0.4,0.2,0.9,0.5c0.5,0.2,0.8,0.5,1,0.7S19.5,20.9,19.5,21.3z"
          stroke="none"
          strokeLinejoin="miter"
        />
        <rect height="14" width="30" fill="none" stroke={fill} x="1" y="13" />
      </g>
    </svg>
  );
}

export default FileJS_32px;
