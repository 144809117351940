"use client";

import { VariantProps, cva } from "class-variance-authority";
import { CtaFragment } from "contentful/gql/graphql";
import ArrowRight from "icons/ArrowRight";
import ArrowTopRight from "icons/ArrowTopRight";
import { analytics } from "lib/segment";
import NextLink from "next/link";
import { HTMLProps } from "react";
import { FiCircle } from "react-icons/fi";
import { cn, getSectionIdForLinkOrButton } from "utils/functions";

const ctaLinkVariantClasses = cva(["group relative inline-flex items-center whitespace-nowrap leading-none"], {
  variants: {
    textAlign: {
      left: "flex justify-start",
      center: "flex justify-center",
      right: "flex justify-end",
    },
    intent: {
      primary:
        "rounded text-primary-neutral-50 transition hover:text-tertiary-pink-500 dark:hover:text-primary-neutral-50",
      secondary:
        "rounded border border-tertiary-pink-500 text-tertiary-pink-500 transition-colors duration-300 hover:bg-tertiary-pink-500/10 dark:border-primary-neutral-50 dark:text-primary-neutral-50 dark:hover:bg-primary-neutral-50/20",
      tertiary: "",
      link: "",
      business:
        "border-primary-brand-500 rounded border bg-secondary-brand-500 text-primary-neutral-50 transition-colors hover:bg-secondary-brand-500/10 hover:text-secondary-brand-500",
      free: "rounded border border-primary-dark-900 bg-primary-dark-900 text-primary-neutral-50 transition-colors hover:bg-primary-dark-900/10 hover:text-primary-dark-900",
      free_secondary:
        "rounded border border-primary-dark-900 bg-primary-dark-900/0 text-primary-dark-900 transition-colors hover:bg-primary-dark-900/10",
      enterprise:
        "rounded border border-tertiary-pink-500 bg-tertiary-pink-500 text-primary-neutral-50 transition-colors hover:bg-tertiary-pink-500/10 hover:text-tertiary-pink-500",
    },
    fontSize: {
      small: "text-2xs lg:text-xs 2xl:text-xs font-display font-bold",
      medium: "text-xs lg:text-sm 2xl:text-sm font-display font-bold",
    },
    paddingSize: {
      small: "px-xs py-3xs xl:px-sm xl:py-[10px]",
      medium: "px-sm py-2xs sm:px-sm sm:py-xs xl:px-md xl:py-sm 2xl:px-lg",
    },
  },
  compoundVariants: [
    {
      intent: "link",
      class: "px-0 py-0 sm:px-0 sm:py-0 xl:px-0 xl:py-0 2xl:px-0",
    },
  ],
  defaultVariants: {
    intent: "primary",
    paddingSize: "medium",
    fontSize: "medium",
  },
});

type sizeType = "sm" | "md" | "lg" | "small" | "medium";

const getIcon = (icon?: string | null) => {
  switch (icon) {
    case "Arrow Right":
      return;
    case "Circle":
      return <FiCircle />;
    default:
      return null;
  }
};

const CtaLink = ({
  label,
  link,
  openInNewWindow,
  hierarchy,
  variant,
  size,
  startIcon,
  endIcon,
  textAlign,
  extendClassNames,
  overrideClassNames,
  sys,
  additionalType,
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, "size" | "label"> &
  Omit<CtaFragment, "__typename" | "sys"> & {
    overrideClassNames?: string;
    extendClassNames?: string;
    textAlign?: "left" | "center" | "right";
    sys?: { id?: string };
    additionalType?: string;
  }) => {
  if (!variant) {
    if (hierarchy == "Orange Fill") {
      variant = "primary";
    } else if (hierarchy == "Orange Ghost") {
      variant = "secondary";
    } else if (hierarchy == "Orange Link") {
      variant = "link";
    } else if (hierarchy == "outlined") {
      variant = "secondary";
    } else if (hierarchy == "Violet Outline") {
      variant = "secondary";
    } else if (hierarchy == "Black Fill") {
      variant = "secondary";
    }
  } else {
    variant = variant?.toLowerCase();
  }

  size = size == "sm" ? "small" : size == "md" ? "medium" : size == "lg" ? "medium" : size?.toLowerCase();

  const isInternalLink =
    link?.startsWith("/") || link?.startsWith("#") || link?.startsWith("?") || link?.includes("appsmith.com");

  // Use relative paths for internal links
  link = link?.includes("https://www.appsmith.com") ? link.split("https://www.appsmith.com")[1] : link;
  link = link?.includes("https://appsmith.com") ? link.split("https://appsmith.com")[1] : link;

  return (
    <NextLink
      href={link || ""}
      onClick={(e) => {
        let sectionId = getSectionIdForLinkOrButton(e.currentTarget as HTMLAnchorElement);
        analytics?.track("Clicked CTA", {
          label,
          link_url: link,
          page_section: sectionId,
          id: sys?.id,
        });
      }}
      className={cn(
        overrideClassNames
          ? overrideClassNames
          : ctaLinkVariantClasses({
              intent: variant as VariantProps<typeof ctaLinkVariantClasses>["intent"],
              textAlign: textAlign,
              paddingSize:
                variant !== "link" ? (size as VariantProps<typeof ctaLinkVariantClasses>["paddingSize"]) : null,
              fontSize: size as VariantProps<typeof ctaLinkVariantClasses>["fontSize"],
              class: extendClassNames,
            }),
        additionalType === "CTA" && variant === "link" ? "hover:bg-transparent border-0" : "",
      )}
      target={openInNewWindow ? "_blank" : "_self"}
      {...(rest.onClick && { onClick: rest.onClick })}
    >
      <span className="relative z-10 flex items-center justify-center whitespace-nowrap leading-none">
        {startIcon && (
          <span className={"mr-3xs"} aria-hidden="true">
            {getIcon(startIcon)}
          </span>
        )}
        {variant === "link" ? (
          <span className="relative">
            <span className="pointer-events-none absolute left-0 top-full h-[1px] w-full origin-[100%_50%] scale-x-0 bg-current transition-transform duration-300 group-hover:origin-[0%_50%] group-hover:scale-x-100"></span>
            {label}
          </span>
        ) : (
          label
        )}
        {endIcon && (
          <span
            className={cn(
              "ml-3xs transition-transform duration-300",
              isInternalLink && !openInNewWindow ? "group-hover:translate-x-1" : "",
            )}
            aria-hidden="true"
          >
            {!openInNewWindow ? <ArrowRight width={"12px"} /> : <ArrowTopRight width={"12px"} />}
          </span>
        )}
      </span>

      {variant === "primary" && !overrideClassNames && additionalType != "CTA" && (
        <>
          <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-tertiary-pink-500 bg-gradient-to-br from-tertiary-pink-500/10 to-tertiary-pink-700/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-tertiary-pink-500/50 dark:to-tertiary-pink-700/50"></span>
          <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-tertiary-pink-500 to-tertiary-pink-700  transition-opacity duration-300 group-hover:opacity-0"></span>
        </>
      )}
    </NextLink>
  );
};

export default CtaLink;
