"use client";

import { cn } from "utils/functions";
import { sectionPaddingBottomClass, sectionPaddingTopClass } from "utils/sectionSpacingConstants";
import { AssetFragment } from "contentful/gql/graphql";
import ConversionPanelV3 from "components/ConversionPanel/ConversionPanelV3";
import LowCodeMythTruthHeading from "./LowCodeMythTruthHeading";
import LowCodeMythTruthCompare from "./LowCodeMythTruthCompare";
import LowCodeMythTruthLike from "./LowCodeMythTruthLike";

type LowCodeMythTruthType = {
  componentType: string;
  content: any;
};

export default function LowCodeMythTruth({
  id,
  json,
  assets,
}: {
  id: string;
  json: {
    componentTheme: string;
    paddingTop: string;
    paddingBottom: string;
    content: LowCodeMythTruthType[] | undefined;
  };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <section
      id={id}
      className={cn(
        json.componentTheme?.toLowerCase() == "dark"
          ? "dark bg-primary-dark-900 text-primary-neutral-100"
          : "bg-primary-neutral-50 text-primary-dark-800",
      )}
    >
      <div
        className={cn(
          "container mx-auto",
          sectionPaddingTopClass[(json?.paddingTop as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          sectionPaddingBottomClass[(json?.paddingBottom as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          "flex flex-col gap-md",
        )}
      >
        {json.content?.map((item, index) => {
          switch (item.componentType) {
            case "heading":
              return <LowCodeMythTruthHeading json={item.content} />;
            case "low-code-what-works-when-table":
            return <LowCodeMythTruthCompare json={item.content} />;
            case "pereferece-cards":
            return <LowCodeMythTruthLike json={item.content} />;
            case "cta-card":
              const data: any = {};
              if (item.content?.[0]?.elementLink) {
                data["callToActionCollection"] = {
                  items: [item.content?.[0]?.elementLink],
                };
              }
              return (
                <div className="pb-lg">
                  <ConversionPanelV3
                    key={`LowCodeDevs${item.componentType}-${index}`}
                    variation={"dark"}
                    heading={item.content?.[0]?.elementTitle || ""}
                    headingType={"H3"}
                    {...data}
                    // accentImage={accentImage}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </section>
  );
}
