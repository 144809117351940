"use client";

import clsx from "clsx";
import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import typography from "components/typography";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CodeEditor_24px from "icons/CodeEditor_24px";
import FileJS_32px from "icons/FileJS_32px";
import GitMerge_24px from "icons/GitMerge_24px";
import Image from "next/image";
import { createRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Transition, TransitionGroup } from "react-transition-group";

const icons = {
  Git: <GitMerge_24px strokewidth="1.5" fill="#7441F6" />,
  CodeEditor: <CodeEditor_24px strokewidth="1.5" fill="#7441F6" />,
  FileJs: <FileJS_32px strokewidth="1.5" fill="#7441F6" />,
};

export interface HomepageDevWorkflowsConfigType {
  items: Item[];
}

interface Item {
  icon: string;
  heading: string;
  body: string;
  image: string;
}

export default function HomepageDevWorkflows(props: {
  json: HomepageDevWorkflowsConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
  components: (ComponentHeadingFragment | undefined)[] | undefined;
}) {
  const options = { threshold: 1 };
  const { ref: ref1, inView: inView1, entry: entry1 } = useInView(options);
  const { ref: ref2, inView: inView2, entry: entry2 } = useInView(options);
  const { ref: ref3, inView: inView3, entry: entry3 } = useInView(options);
  const refs = [ref1, ref2, ref3];
  const nodeRefs = [createRef<HTMLImageElement>(), createRef<HTMLImageElement>(), createRef<HTMLImageElement>()];
  const inViews = [inView1, inView2, inView3];
  const [visibleItemIndex, setVisibleItemIndex] = useState(0);
  const tl = useRef<any>(null);
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const imageRef = useRef(null);
  const scrollerRef = useRef(null);

  const transitionStyles = {
    entering: { opacity: 1, transition: `opacity 600ms ease-in-out` },
    entered: { opacity: 1 },
    exiting: { opacity: 0, transition: `opacity 300ms ease-out` },
    exited: { opacity: 0 },
    unmounted: {},
  };

  useEffect(() => {
    // When an item is in view, set it as the visible item
    if (inView1) {
      setVisibleItemIndex(0);
    } else if (inView2) {
      setVisibleItemIndex(1);
    } else if (inView3) {
      setVisibleItemIndex(2);
    }
  }, [inView1, inView2, inView3]);

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    mm.add(
      "(min-width: 768px)",
      (self) => {
        gsap.registerPlugin(ScrollTrigger);
        let features = self.selector && self.selector("[data-gsap-element='feature']");
        features.forEach((element: any) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: element,
                start: "top 90%",
                end: "bottom top+=180px",
                scrub: true,
              },
            })
            .to(element, {
              opacity: 1,
            })
            .to(element, {
              opacity: 0,
            });
        });
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top top+=140px",
          end: "bottom bottom-=140px",
          pin: headingRef.current,
          pinSpacing: false,
        });

        ScrollTrigger.create({
          trigger: scrollerRef.current,
          pin: imageRef.current,
          start: "top 30%",
          end: "bottom 80%",
        });
      },
      sectionRef,
    );

    return () => mm.revert();
  }, []);

  const headingComponent = useMemo(
    () =>
      props.components && props.components[0] ? (
        <ComponentHeading {...props.components[0]} pinnedContainer={headingRef.current} />
      ) : undefined,
    [props.components],
  );
  return (
    <div className="relative" ref={sectionRef} data-gsap-element="dev-workflow-section">
      <div className="mb-sm md:mb-lg" ref={headingRef} data-gsap-element="dev-workflow-heading">
        {headingComponent}
      </div>

      <div className="hidden md:grid md:grid-cols-2" ref={scrollerRef}>
        <div className="mt-[15vh] space-y-[50vh] pb-[20vh] xl:space-y-[40vh] 2xl:space-y-[50vh]">
          {props.json.items.map((item, index) => {
            const { icon, heading, body } = item;
            return (
              <div data-gsap-element="feature" className="opacity-0" ref={refs[index]} key={item.heading}>
                <div className="mb-xs h-[50px] w-[50px] rounded-full bg-primary-light-500/10 p-[14px]">
                  {icons[icon as keyof typeof icons]}
                </div>
                <h3
                  className={clsx(
                    typography.heading.componentHeading,
                    "mb-2xs text-primary-dark-800 dark:text-primary-neutral-50",
                  )}
                >
                  {heading}
                </h3>
                <p
                  className={clsx(
                    typography.body.componentBody,
                    "text-primary-dark-800/70 dark:text-primary-neutral-50/80",
                  )}
                >
                  {body}
                </p>
              </div>
            );
          })}
        </div>
        <div className="relative h-[50vh]" ref={imageRef}>
          <TransitionGroup>
            {props.json.items.map((item, index) => {
              const { icon, heading, body } = item;
              const image = props.assets?.find((asset) => asset?.title === item.image);
              const nodeRef = nodeRefs[index];
              return (
                visibleItemIndex === index && (
                  <Transition key={index} timeout={600} nodeRef={nodeRef}>
                    {(state) => (
                      <Image
                        ref={nodeRef}
                        className="absolute left-1/2 top-1/2 mx-auto h-full w-full -translate-x-1/2 -translate-y-1/2 object-contain pl-md opacity-0"
                        style={{
                          ...transitionStyles[state],
                        }}
                        src={image?.url ?? ""}
                        height={image?.height ?? 0}
                        width={image?.width ?? 0}
                        alt={image?.title ?? "Image"}
                      />
                    )}
                  </Transition>
                )
              );
            })}
          </TransitionGroup>
        </div>
      </div>
      <div className="grid gap-md md:hidden">
        {props.json.items.map((item, index) => {
          const { icon, heading, body } = item;
          const image = props.assets?.find((asset) => asset?.title === item.image);
          return (
            <div key={index} className="grid">
              <div className="mb-sm text-center">
                <div className="mx-auto mb-xs h-lg w-lg rounded-full bg-primary-light-500/10 p-[18px]">
                  {icons[icon as keyof typeof icons]}
                </div>
                <h3
                  className={clsx(
                    typography.heading.componentHeading,
                    "text-primary-dark-800 dark:text-primary-neutral-50",
                  )}
                >
                  {heading}
                </h3>
                <p
                  className={clsx(
                    typography.body.componentBody,
                    "text-primary-dark-800/70 dark:text-primary-neutral-50/80",
                  )}
                >
                  {body}
                </p>
              </div>
              {image && (
                <Image
                  src={image.url ?? ""}
                  height={image.height ?? 0}
                  width={image.width ?? 0}
                  alt={image.title ?? "Image"}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
