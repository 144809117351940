import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateWidgetListingFragment } from "contentful/gql/graphql";
import Image from "next/image";

const WidgetCard = ({ widget }: { widget: TemplateWidgetListingFragment }) => {
  const { title, widgetImage: image, externalLink, slug } = widget;
  const widgetImage = getFragmentData(assetFragment, image);
  return (
    <Hyperlink
      href={externalLink ?? `https://docs.appsmith.com/reference/widgets/${slug}`}
      className="group flex flex-col items-center justify-center rounded-lg bg-secondary-light-100/60 p-sm text-center dark:bg-primary-neutral-50"
    >
      {widgetImage?.url && (
        <div className="mb-sm h-3xl w-3xl rounded-full  p-sm">
          <Image
            className="object-contain"
            width={widgetImage?.width || 256}
            height={widgetImage?.height || 256}
            src={widgetImage?.url || ""}
            alt={widgetImage?.title || "Widget image"}
          />
        </div>
      )}
      <h3 className={clsx(typography.label, "group-hover:underline")}>{title}</h3>
    </Hyperlink>
  );
};

export default WidgetCard;
