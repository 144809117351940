import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { FC, Fragment } from 'react';

interface TemplateFilterMobileProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  filters: string[];
  handleFilter: (option: string) => void;
  categories: (string | null | undefined)[];
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const TemplateFilterMobile: FC<TemplateFilterMobileProps> = ({
  isOpen,
  setOpen,
  filters,
  categories,
  handleFilter,
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="bg-black fixed inset-0 bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 z-40 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-primary-neutral-50 p-xs shadow-xl">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium ">Filters</h2>
              <button type="button" className="flex items-center justify-center" onClick={() => setOpen(false)}>
                <span className="sr-only">Close menu</span>
                <FiX className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Filters */}
            <form className="py-xs">
              <fieldset>
                <legend className="w-full">
                  <span className="text-base font-medium">Category</span>
                </legend>
                <div className="space-y-6">
                  {categories?.map(
                    option =>
                      option && (
                        <div key={`cat-${option}`} className="flex items-center">
                          <input
                            id={`${option}-mobile`}
                            checked={filters.includes(option)}
                            onChange={() => handleFilter(option)}
                            type="checkbox"
                            className="rounded"
                          />
                          <label htmlFor={`${option}-mobile`} className="ml-2xs text-base">
                            {option}
                          </label>
                        </div>
                      ),
                  )}
                </div>
              </fieldset>
            </form>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default TemplateFilterMobile;
