import CtaLink from "components/CtaLink";
import { assetFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentCustomJsonFragment } from "contentful/gql/graphql";
import { cn } from "utils/functions";
import Image from "next/image";

type SidebarConversionPanelJSON = {
  heading: string;
};

export default function SidebarConversionPanel(sidebar: ComponentCustomJsonFragment) {
  const json = sidebar.json as SidebarConversionPanelJSON;
  const ctas = sidebar.componentReferencesCollection?.items
    .map((item) => (item?.__typename === "ComponentCallToAction" ? getFragmentData(ctaFragment, item) : null))
    .filter((item) => item !== null);
  const bgImg = getFragmentData(assetFragment, sidebar.assetReferencesCollection?.items[0]);
  return (
    <div className="sticky top-2xl">
      <div className="dark relative mt-xs overflow-hidden rounded-lg p-xs">
        <div className="relative z-10">
          <h2 className="font-display text-2xl font-extrabold text-primary-neutral-50">{json.heading}</h2>
          {ctas && ctas.length > 0 ? (
            <div className="mt-sm grid w-full space-y-3xs">
              {ctas.map((cta, idx) => (
                <CtaLink
                  key={idx}
                  {...cta}
                  size={"Small"}
                  textAlign="center"
                  variant={idx === 1 ? "secondary" : "primary"}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className={cn("noiseBgDark absolute left-0 top-0 -z-10 h-full w-full")}></div>
        <div className={cn("wavesBg absolute left-0 top-0 -z-10 h-full w-full")}></div>
        {bgImg ? (
          <Image
            className="absolute -right-[42%] top-1/2 h-full w-auto -translate-y-1/3"
            alt=""
            src={bgImg.url ?? ""}
            height={bgImg.height ?? 0}
            width={bgImg.width ?? 0}
          />
        ) : null}
      </div>
      <style jsx>{`
        .noiseBgDark {
          background: url("/assets/images/noise.svg"),
            radial-gradient(217.61% 66.86% at 102.34% 84.42%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
            radial-gradient(131.42% 55.31% at -2.19% 19.69%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%),
            #1f293d;
          background-blend-mode: multiply, overlay, overlay, normal;
        }

        @media screen and (max-width: 480px) {
          .noiseBgDark {
            background:
              url("/assets/images/noise.svg") 0/100% 100%,
              radial-gradient(
                217.61% 66.86% at 102.34% 84.42%,
                rgba(255, 255, 255, 0.6) 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              radial-gradient(
                131.42% 55.31% at -2.19% 19.69%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              #1f293d;
            background-blend-mode: multiply, overlay, overlay, normal;
          }
        }

        .wavesBg {
          background: url("/assets/images/waves_bg.webp") 20% 55% / 450% no-repeat;
          mix-blend-mode: overlay;
        }
      `}</style>
    </div>
  );
}
