"use client";

import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentLessonFragment, ComponentVideoFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { useState } from "react";
import { getYoutubeId, parseVimeo } from "utils/parseUrl";
import richTextParser, { RichText } from "utils/richTextParser";
import { Dialog } from "@headlessui/react";
import Icon from "components/Icon";

const VideoComponentCard = ({
  video,
  lesson,
}: {
  video?: ComponentVideoFragment;
  lesson?: ComponentLessonFragment;
}) => {
  let [isOpen, setIsOpen] = useState(false);
  if (!video) return null;
  const { title, url, thumbnail: image, body } = video;
  const thumbnail = getFragmentData(assetFragment, image);
  const youtubeID = getYoutubeId(url || "");
  const videoUrl = youtubeID ? `https://www.youtube.com/embed/${youtubeID}` : parseVimeo(url || "");

  return (
    <div className="bg-white group h-full overflow-hidden rounded-lg shadow-lg" onClick={() => setIsOpen(true)}>
      <div className="bg-gray-100 relative h-48 flex-shrink-0 transition-opacity group-hover:opacity-75">
        {thumbnail?.url && (
          <Image
            className="h-48 w-full object-cover"
            src={thumbnail?.url}
            alt={thumbnail?.title || "Video thumbnail Image"}
            width={thumbnail.width || 832}
            height={thumbnail.height || 520}
          />
        )}
        <span
          className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center"
          aria-hidden="true"
        >
          <svg className="h-20 w-20 " fill="currentColor" viewBox="0 0 84 84">
            <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
          </svg>
        </span>
      </div>
      <div className="flex flex-1 flex-col justify-between p-6">
        <div className="flex-1">
          <p className="flex items-center justify-between text-sm font-semibold lg:flex-none">
            <span className="hover:underline ">Video</span>
          </p>
          <h4 className="mt-2 text-xl font-medium  leading-6">{title}</h4>
          <div className="mb-0 mt-3 text-base">{richTextParser(body as RichText)}</div>
        </div>
      </div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-secondary-dark-700/95" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="relative aspect-video w-full max-w-screen-lg rounded-xl p-xs">
            <iframe
              src={videoUrl || ""}
              title=""
              width="100%"
              height="100%"
              allowFullScreen
              allow="accelerometer; clipboard-write;encrypted-media; gyroscope; picture-in-picture"
              className="rounded-xl"
            />
            <button
              onClick={() => setIsOpen(false)}
              className="absolute -top-sm right-sm h-md w-md rounded-full bg-primary-dark-500 p-3xs text-primary-neutral-50"
            >
              <Icon url="/assets/icons/e-remove-glyph-16.svg" />
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default VideoComponentCard;
