import React from 'react';
import { IconProps } from './IconProps';

function GitMerge_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'GitMerge 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill} stroke={fill}>
        <path
          d="M3 23l9-9V2"
          fill="none"
          stroke={fill}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M15 17l6 6"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M17 7l-5-5-5 5"
          fill="none"
          stroke={fill}
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
}

export default GitMerge_24px;
