import { cn } from "utils/functions";
import typography from "components/typography";
import Icon from "components/Icon";
import ListItemIconText from "components/ListItem/ListItemIconText";

type ElementContent = {
  title: string;
  content: {
    icon: string;
    label: string;
  }[];
};

export default function StackedCompareTable({ json }: { json: ElementContent[] }) {
  return (
    <div className={cn("flex flex-col gap-md lg:flex-row")}>
      {json.map((item, index) => {
        return (
          <div className={cn("flex flex-col gap-xs")} key={`StackedCompareTable-${index}`}>
            <h3 className={cn(typography.heading.secondaryComponentHeading)}>{item.title}</h3>
            <div className="flex flex-col gap-xs">
              {item.content.map((content, ind) => {
                return (
                  <ListItemIconText
                    key={`StackedCompareTable-${ind}`}
                    clName="relative flex gap-3xs"
                    text={content.label}
                    icon={content.icon}
                    textClass={null}
                    iconClass={null}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
