"use client";

import { JumplistProvider } from "@faceless-ui/jumplist";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import Jumplist from "components/Jumplist";

export default function HomepageBuildCustomApp({
  json,
  componentHeading,
  assets,
}: {
  json: {
    tabs: { title: string; refAssetId: string; refHeadingComponent: string; componentType: string }[];
    ctaButton: { ctaButtonLink: string; ctaButtonText: string };
  };
  componentHeading: (ComponentHeadingFragment | undefined)[] | undefined;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <JumplistProvider threshold={0.05} rootMargin="-100px 0px 0px 0px" smoothScroll>
      <div className="flex flex-col items-center">
        <Jumplist json={json} componentHeading={componentHeading} assets={assets} />
        <div className="mt-2xs flex gap-sm md:mt-sm 2xl:mt-md">
          <a
            className="group relative inline-flex items-center whitespace-nowrap rounded px-sm py-2xs font-display text-xs font-bold text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50 sm:px-sm sm:py-xs lg:text-sm xl:px-md xl:py-sm 2xl:px-lg 2xl:text-sm"
            target="_self"
            href={json?.ctaButton.ctaButtonLink}
          >
            <span className="relative z-10 flex items-center justify-center whitespace-nowrap leading-none">
              {json?.ctaButton.ctaButtonText}
            </span>
            <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
            <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
          </a>
        </div>
      </div>
    </JumplistProvider>
  );
}
