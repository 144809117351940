'use client';

import { cva } from "class-variance-authority";
import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import CtaLink from "components/CtaLink";
import { componentCellFragment, componentRowFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import {
  ComponentCellFragment,
  ComponentHeadingFragment,
  ComponentPricingComparisonTableFragment,
  ComponentRowFragment,
} from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef, useState } from "react";
import { cn } from "utils/functions";
import Cell from "./Cell";
import PricingComparisonTableMobile from "./PricingComparisonTableMobile";
import Icon from "components/Icon";

const cellStyles = cva([], {
  variants: {
    type: {
      normal: "border-primary-dark-100",
      highlighted: "border-primary-light-500",
    },
    location: {
      first: "rounded-t-lg border-x border-t lg:rounded-t-xl",
      middle: "border-x",
      last: "rounded-b-lg border-x border-b lg:rounded-b-xl",
    },
  },
});

interface PricingComparisonsTableProps extends ComponentPricingComparisonTableFragment {
  heading: ComponentHeadingFragment;
  headerRow: (ComponentCellFragment | null | undefined)[] | undefined;
  pricingRow: (ComponentCellFragment | null | undefined)[] | undefined;
  bodyRowCollection: (ComponentRowFragment | null | undefined)[] | undefined;
}

const PricingComparisonsTable = (props: PricingComparisonsTableProps) => {
  const sectionRef = useRef(null);
  const headerRef = useRef(null);
  const [entered, setEntered] = useState(false);
  const headerRow = props.headerRow;
  const pricingRow = props.pricingRow;
  const bodyRowCollection = props.bodyRowCollection;

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    mm.add(
      "(min-width: 768px)",
      () => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top top+=48px",
          onEnter: () => setEntered(true),
          onLeaveBack: () => setEntered(false),
        });
      },
      sectionRef,
    );

    return () => mm.revert();
  }, []);

  return (
    <div id="plan-comparison" className="w-full overflow-visible pl-0 lg:p-sm">
      {props.heading && <ComponentHeading {...props.heading} />}
      <table ref={sectionRef} className="relative hidden h-px lg:table">
        <caption className="sr-only">Pricing plan comparison</caption>
        <thead ref={headerRef} className="bg-primary-neutral-50">
          <tr className="">
            {headerRow &&
              headerRow.map((cell, cellIdx) => (
                <th
                  key={cell?.sys.id}
                  scope="col"
                  className={cn(
                    "sticky bottom-lg top-lg h-full bg-primary-neutral-50 p-0 pt-xl",
                    cellIdx !== 0 ? "w-[25%] min-w-[120px] px-3xs" : "",
                  )}
                >
                  <div
                    className={cn(
                      "flex h-full flex-col px-xs py-xs",
                      cellIdx !== 0 ? cellStyles({ type: "normal", location: "first" }) : "",
                      cellIdx === 3 ? cellStyles({ type: "highlighted", location: "first" }) : "",
                      cellIdx !== 0 && entered ? "border-x border-b border-b-primary-dark-100" : "",
                    )}
                  >
                    <span className="sr-only">Feature by</span>
                    <div
                      className={cn("w-full text-left text-xl font-bold lg:text-2xl xl:text-3xl", {
                        "text-secondary-brand-500": cellIdx == 2,
                        "bg-gradient-to-br from-primary-light-500 to-primary-light-300": cellIdx == 3,
                      })}
                      style={
                        cellIdx == 3
                          ? {
                              backgroundClip: "text",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              color: "transparent",
                            }
                          : undefined
                      }
                    >
                      {cell?.heading}
                    </div>
                    {pricingRow &&
                      (() => {
                        const cell = pricingRow[cellIdx];
                        if (!cell) {
                          return null;
                        }
                        const ctas = cell?.callToActionsCollection?.items.map((cta) =>
                          getFragmentData(ctaFragment, cta),
                        );
                        return (
                          <>
                            {cellIdx === 3 ? (
                              <div className="flex flex-col items-start justify-center">
                                <div className="h-9 w-9 text-primary-light-500">
                                  <Icon url="/assets/icons/comments-24-outline.svg" />
                                </div>
                                <div className="text-xs font-normal">Contact sales for pricing</div>
                              </div>
                            ) : cellIdx === 2 ? (
                              <div className={cn("w-full text-left text-sm font-normal xl:text-sm")}>
                                {cell?.heading}
                              </div>
                            ) : (
                              <div className={cn("w-full text-left text-sm font-normal xl:text-md")}>
                                {cell?.heading}
                              </div>
                            )}

                            {ctas && (
                              <div className="mt-auto flex space-x-3xs pt-xs">
                                {ctas.map((cta, ctaIdx) => {
                                  return (
                                    <CtaLink
                                      key={cta?.sys.id || "" + cellIdx + ctaIdx}
                                      {...cta}
                                      extendClassNames="w-full"
                                      textAlign="center"
                                      size={"Small"}
                                      variant={
                                        cellIdx === 1
                                          ? ctaIdx == 0
                                            ? "free"
                                            : "free_secondary"
                                          : cellIdx === 2
                                            ? "business"
                                            : cellIdx === 3
                                              ? "enterprise"
                                              : undefined
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </>
                        );
                      })()}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {bodyRowCollection &&
            bodyRowCollection.map((rowFragment, rowIdx) => {
              const row = {
                ...rowFragment,
                cellCollection: rowFragment?.cellCollection?.items.map((cell) =>
                  getFragmentData(componentCellFragment, cell),
                ),
              };
              return (
                <tr key={row.sys?.id} className="h-full">
                  {!!row.cellCollection?.length && row.cellCollection?.length > 1 ? (
                    row.cellCollection?.map((cell, cellIdx) => {
                      if (!cell) {
                        return null;
                      }
                      return (
                        <td
                          key={rowIdx + "-" + row.sys?.id + cellIdx + "-" + cell.sys.id}
                          className={cn("h-full p-0", cellIdx !== 0 ? "px-3xs" : "")}
                        >
                          <Cell cell={cell} cellIdx={cellIdx} isLastRow={rowIdx === bodyRowCollection.length - 1} />
                        </td>
                      );
                    })
                  ) : (
                    <>
                      <th className="sticky top-[200px] bg-primary-neutral-50 p-0 text-left lg:top-[240px] xl:top-[260px]">
                        <div className="py-4xs pt-xs text-base font-medium text-primary-light-500 xl:text-xl">
                          {row?.cellCollection?.[0]?.heading}
                        </div>
                      </th>
                      <th className=" h-full bg-primary-neutral-50 px-3xs py-0">
                        <div className="flex h-full w-full border-x border-primary-dark-100 pt-xs">
                          <hr className="mx-xs my-auto w-[calc(100%-32px)] border-primary-dark-500/30" />
                        </div>
                      </th>
                      <th className=" h-full bg-primary-neutral-50 px-3xs py-0">
                        <div className="flex h-full w-full border-x border-primary-dark-100 pt-xs">
                          <hr className="mx-xs my-auto w-[calc(100%-32px)] border-primary-dark-500/30" />
                        </div>
                      </th>
                      <th className=" h-full bg-primary-neutral-50 px-3xs py-0">
                        <div className="flex h-full w-full border-x border-primary-light-500 pt-xs">
                          <hr className="mx-xs my-auto w-[calc(100%-32px)] border-primary-dark-500/30" />
                        </div>
                      </th>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="lg:hidden">
        <PricingComparisonTableMobile {...props} />
      </div>
    </div>
  );
};

export default PricingComparisonsTable;
