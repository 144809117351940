import { clsx } from "clsx";
import Hyperlink from "components/Hyperlink";
import Link from "components/Hyperlink";
import typography from "components/typography";
import {
  assetFragment,
  componentStatisticFragment,
  componentStatisticPanelFragment,
  entityCompanyFragment,
  metaSeoFragment,
} from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateCaseStudyListingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { adjustImageDimensions, cn, randomIntInRange } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

export const CaseStudyCard = (caseStudy: TemplateCaseStudyListingFragment & { index?: number }) => {
  const {
    title,
    slug,
    category,
    seo,
    __typename: type,
    author,
    publishDate,
    industry,
    sys,
    company: companyFragment,
    ...rest
  } = caseStudy;
  const featuredImage = getFragmentData(assetFragment, getFragmentData(metaSeoFragment, seo)?.openGraphImage);
  return (
    <Hyperlink
      className="group flex h-full flex-col overflow-hidden rounded-lg bg-secondary-light-100/60 hover:cursor-pointer dark:bg-primary-dark-700"
      href={`/case-study/${slug}`}
    >
      <div className="relative">
        <Image
          className="aspect-[2.1] w-full object-cover"
          src={featuredImage?.url || ""}
          alt={title || "Case study image"}
          width={featuredImage?.width || 304}
          height={featuredImage?.height || 144}
          sizes="(max-width: 640px) 100vw, 30vw"
        />
      </div>
      <div className="flex-1 px-sm pb-md pt-xs">
        <div className="flex space-x-2xs">
          {[category, industry].map((tag) => (
            <div
              key={sys.id + "-" + tag}
              className={clsx(
                "mb-3xs flex max-w-fit items-center justify-center rounded-full bg-primary-light-500/10 px-xs py-4xs text-xs font-medium leading-none text-primary-light-500",
              )}
            >
              {tag}
            </div>
          ))}
        </div>

        <h3 className={clsx(typography.heading.cardHeading, "group-hover:underline")}>{title}</h3>
      </div>
    </Hyperlink>
  );
};

export default CaseStudyCard;

export const FeaturedCaseStudyCard = ({
  caseStudy,
  useStandardImage,
}: {
  caseStudy: TemplateCaseStudyListingFragment;
  useStandardImage: Boolean;
}) => {
  const {
    title,
    slug,
    category,
    seo,
    __typename: type,
    author,
    publishDate,
    industry,
    statisticalHighlights,
    sys,
    company: companyFragment,
    ...rest
  } = caseStudy;
  const thumbnailBgUrl = `/assets/images/casestudy-thumbnail-bg-6.webp`;
  const featuredImage = getFragmentData(assetFragment, getFragmentData(metaSeoFragment, seo)?.openGraphImage);
  const statisticItems = getFragmentData(componentStatisticPanelFragment, statisticalHighlights)?.statisticCollection
    ?.items;

  const company = getFragmentData(entityCompanyFragment, companyFragment);

  const companyLogo = getFragmentData(assetFragment, company?.logoOnDark || company?.logoIcon);
  const { width: companyLogoWidth, height: companyLogoHeight } = adjustImageDimensions({
    baseWidth: 100,
    scaleFactor: 0.55,
    width: companyLogo?.width || 0,
    height: companyLogo?.height || 0,
  });

  return (
    <Hyperlink
      href={`/case-study/${slug}`}
      className="group flex flex-col overflow-hidden rounded-lg bg-secondary-light-100/60 md:flex-row"
    >
      <div className="relative md:w-1/2">
        {!useStandardImage ? (
          <Image
            className="h-full w-full object-cover"
            src={featuredImage?.url || ""}
            alt={title || "Featured case study image"}
            width={featuredImage?.width || 608}
            height={featuredImage?.height || 368}
            sizes="(max-width: 640px) 100vw, 30vw"
          />
        ) : (
          <>
            <Image
              className="h-full w-full bg-primary-dark-900 object-cover"
              src={thumbnailBgUrl}
              alt={title || "Thumbnail Image"}
              width={608}
              height={368}
              sizes="(max-width: 640px) 100vw, 30vw"
            />
            {companyLogo?.url ? (
              <Image
                src={companyLogo?.url || ""}
                alt={companyLogo?.title || "Company logo"}
                width={companyLogoWidth || 128}
                height={companyLogoHeight || 128}
                className={clsx(
                  "absolute left-1/2 top-1/2 z-10 h-3_5xl w-3_5xl -translate-x-1/2 -translate-y-1/2 object-contain brightness-0 invert",
                )}
              />
            ) : (
              <span
                className={clsx(
                  "absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 font-display text-xl font-bold text-primary-neutral-50",
                )}
              >
                Case Study
              </span>
            )}
          </>
        )}
      </div>
      <div className="h-full md:w-1/2">
        <div className="h-full flex-1 p-md">
          <div className="flex space-x-2xs">
            {[category, industry].map((tag) => (
              <div
                key={sys.id + "-" + tag}
                className={clsx(
                  "mb-3xs flex max-w-fit items-center justify-center rounded-full bg-primary-light-500/10 px-xs py-4xs text-xs font-medium leading-none text-primary-light-500",
                )}
              >
                {tag}
              </div>
            ))}
          </div>

          <h3
            className={cn(typography.heading.secondaryComponentHeading, "mb-md leading-normal group-hover:underline")}
          >
            {title}
          </h3>
          <div>
            <ul className="mt-auto grid grid-cols-2 gap-sm sm:auto-cols-fr sm:grid-flow-col-dense 2xl:gap-md">
              {statisticItems &&
                statisticItems.map((item, idx) => {
                  const statistic = getFragmentData(componentStatisticFragment, item);
                  return (
                    <li
                      key={statistic?.sys?.id}
                      className={cn(
                        "text-center sm:col-span-1",
                        statisticItems.length % 2 === 1
                          ? idx === statisticItems.length - 1
                            ? "col-span-2"
                            : null
                          : null,
                      )}
                    >
                      <h3>
                        <div
                          className={cn(
                            "whitespace-nowrap font-display text-xl font-bold text-primary-light-500 md:text-2xl",
                            "mb-3xs",
                          )}
                        >
                          {statistic?.heading}
                        </div>
                        <div
                          className={cn(
                            typography.heading.cardHeading,
                            "[&_p]:m-0 [&_p]:text-base [&_p]:font-normal [&_p]:text-primary-dark-500 [&_p]:xl:text-lg",
                          )}
                        >
                          {richTextParser(statistic?.body as RichText)}
                        </div>
                      </h3>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </Hyperlink>
  );
};
