"use client";

import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import widgetsLottieJson from "lottie/widgets.json";
import { useLayoutEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function ResponsiveUiLottie() {
  const [play, setPlay] = useState(false);
  const [goTo, setGoTo] = useState<number | undefined>(undefined);
  const { ref: lottieInViewRef } = useInView({
    threshold: [0, 1],
    onChange(inView, entry) {
      if (inView && entry.intersectionRatio === 1) {
        setPlay(true);
        setGoTo(undefined);
      } else if (!inView) {
        setPlay(false);
        setGoTo(0);
      }
    },
  });
  return (
    <div ref={lottieInViewRef} className="pb-xs">
      <Lottie
        className="w-full pr-[4%] md:h-[65svh]"
        animationData={widgetsLottieJson}
        goTo={goTo}
        play={play}
        loop={false}
      />
    </div>
  );
}
