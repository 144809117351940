"use client";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import Spotlight, { SpotlightCardContainer } from "components/Cards/SpotlightCard/Spotlight";
import SpotlightCard from "components/Cards/SpotlightCard";
import { AssetFragment } from "contentful/gql/graphql";

export default function HomepageEnterpriseScale({
  json,
  assets,
}: {
  json: { cards: { title: string; body: string; icon: string }[] };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const sectionRef = useRef<any>(null);
  const tl = useRef<any>(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 75%",
          },
        })
        .to(".icon-trail", {
          y: 0,
          duration: 0.6,
          ease: "power2.out",
        })
        .to(
          ".icon",
          {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 0.3,
            ease: "power2.out",
          },
          "< +=0.4",
        )
        .to(
          ".icon-label",
          {
            opacity: 1,
            scale: 1,
            duration: 0.4,
          },
          "< +=0.2",
        );
      ScrollTrigger.create({
        trigger: sectionRef.current,
        onLeaveBack: () => tl.current?.pause(0),
      });
    }, sectionRef);
    return () => ctx.revert();
  }, []);
  return (
    <div className="relative overflow-clip text-center">
      <Spotlight className="group mx-auto grid max-w-sm items-start gap-6 md:max-w-none md:grid-cols-3">
        <>
          {json?.cards?.map((card) => {
            const asset = assets?.find((asset) => asset?.sys?.id === card.icon);
            return (
              <SpotlightCardContainer
                key={card.title}
                className="relative z-20 h-full overflow-hidden bg-primary-neutral-50"
              >
                <SpotlightCard icon={asset} title={card.title} body={card.body} />
              </SpotlightCardContainer>
            );
          })}
        </>
      </Spotlight>
    </div>
  );
}
