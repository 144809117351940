import React from 'react';
import { IconProps } from './IconProps';

function Block_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'Block 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokewidth}
      >
        <line fill="none" x1="12" x2="12" y1="11.5" y2="22.5" />
        <polyline fill="none" points="3 6.5 12 11.5 21 6.5" />
        <polygon fill="none" points="21 17.5 21 6.5 12 1.5 3 6.5 3 17.5 12 22.5 21 17.5" stroke={fill} />
      </g>
    </svg>
  );
}

export default Block_24px;
