import { clsx } from "clsx";
import Link from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment, metaSeoFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateBlogPostListingFragment, TemplateCaseStudyListingFragment } from "contentful/gql/graphql";
import ImageNext from "next/image";
import { formatDate } from "utils/functions";

const BlogCard = ({
  blog,
  caseStudy,
}: {
  blog?: TemplateBlogPostListingFragment;
  caseStudy?: TemplateCaseStudyListingFragment;
}) => {
  const { title, slug, category, seo, __typename: type, author, publishDate, ...rest } = blog || caseStudy || {};
  const blogImage = getFragmentData(assetFragment, getFragmentData(metaSeoFragment, seo)?.openGraphImage);
  const isCaseStudy = type === "TemplateCaseStudy";

  return (
    <Link
      className="group flex h-full flex-col overflow-hidden rounded-2xl bg-secondary-light-100/60 hover:cursor-pointer dark:bg-primary-dark-700"
      href={`/${isCaseStudy ? "case-study" : "blog"}/${slug}`}
    >
      <div>
        <ImageNext
          className="aspect-[2.1] w-full object-cover"
          src={blogImage?.url || ""}
          alt={title || "Blog image"}
          width={blogImage?.width || 304}
          height={blogImage?.height || 144}
          sizes="(max-width: 640px) 100vw, 30vw"
        />
      </div>
      <div className="flex-1 p-sm">
        {category !== "About Us" && (
          <div
            className={clsx(
              "mb-3xs flex max-w-fit items-center justify-center rounded-full bg-primary-light-500/10 px-xs py-4xs text-xs font-medium leading-none text-primary-light-500",
            )}
          >
            {category}
          </div>
        )}
        <span className={clsx(typography.heading.cardHeading, "block group-hover:underline")}>{title}</span>
      </div>
      {!isCaseStudy && (
        <div className="flex items-center space-x-xs p-xs pt-0 xl:p-sm xl:pt-0">
          {author?.headshot?.url ? (
            <div className="flex-shrink-0">
              <span>
                <span className="sr-only">{author?.name}</span>

                <ImageNext
                  className="h-10 w-10 rounded-full object-cover"
                  src={author?.headshot?.url}
                  alt={author?.name || "Author profile photo"}
                  width={40}
                  height={40}
                />
              </span>
            </div>
          ) : null}
          <div>
            <div className="text-ellipsis text-sm font-medium">
              <span className="">{author?.name}</span>
            </div>
            <div className="flex space-x-1 whitespace-nowrap text-xs">
              {publishDate && <time dateTime={publishDate}>{formatDate(publishDate)}</time>}
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};

export default BlogCard;
