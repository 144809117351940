export function isValidEmail(email: string) {
    const genericDomains = new Set([
        "@gmail.com", "@icloud.com", "@mailinator.com", "@business.com", "@edu.com", "@teleworm.com",
        "@yahoo.com", "@hotmail.com", "@aol.com", "@outlook.com", "@live.com", "@msn.com",
        "@comcast.net", "@verizon.net", "@att.net", "@sbcglobal.net", "@bellsouth.net", "@charter.net",
        "@cox.net", "@earthlink.net", "@juno.com", "@mindspring.com", "@netzero.net", "@roadrunner.com",
        "@prodigy.net", "@q.com", "@rocketmail.com", "@ymail.com", "@mail.com", "@inbox.com",
        "@gmx.com", "@hushmail.com", "@fastmail.com", "@zoho.com", "@yandex.com", "@protonmail.com"
    ]);

    const emailDomain = email.substring(email.indexOf('@'));

    return !genericDomains.has(emailDomain);
}