import React from 'react';
import { IconProps } from './IconProps';

function Layout_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'Layout 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokewidth}
      >
        <line fill="none" x1="1" x2="23" y1="9" y2="9" strokeLinecap="round" strokeLinejoin="round" />
        <line fill="none" x1="8" x2="8" y1="9" y2="22" strokeLinecap="round" strokeLinejoin="round" />
        <rect
          height="20"
          width="22"
          fill="none"
          stroke={fill}
          x="1"
          y="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Layout_24px;
