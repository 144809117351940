'use client';

import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import PartnerCard from 'components/Cards/PartnerCard/PartnerCard';
import Tabs from 'components/Tabs/Tabs';
import { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { EntityCompanyFragment } from 'contentful/gql/graphql';

const industries = ['E-commerce', 'Education', 'Healthcare', 'Financial Services', 'SaaS', 'Manufacturing'];

const PartnerListing = ({ partners }: { partners: (EntityCompanyFragment | null)[] | undefined }) => {
  const router = useRouter();
  const tabs = [{ name: 'Services' }, { name: 'Technology' }];
  const [loading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Services');
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [industryFilter, setIndustryFilter] = useState<string[]>([]);
  const [filteredPartners, setFilteredPartners] = useState(partners);

  const onTabChange = (tab: string) => {
    if (tab === 'Technology') {
      router.push('/integration');
      return;
    }

    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      if (industryFilter.length > 0) {
        setFilteredPartners(
          partners?.filter(partner => partner?.industries?.some(ind => industryFilter.includes(ind || ''))),
        );
      } else {
        setFilteredPartners(partners);
      }
    };
    applyFilters();
  }, [industryFilter, partners]);

  return (
    <div>
      <div className="py-4 text-right">
        <Tabs tabs={tabs} selectedTab={selectedTab} onTabChange={onTabChange} />
      </div>
      <div className="gap-sm lg:grid lg:grid-cols-12">
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="bg-black fixed inset-0 bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="bg-white relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium ">Filters</h2>
                    <button
                      type="button"
                      className="hover: -mr-2 flex h-10 w-10 items-center justify-center  p-2"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <FiX className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    <Disclosure as="div" className="border-gray-200 border-t pb-4 pt-4">
                      {({ open }) => (
                        <fieldset>
                          <legend className="w-full px-2">
                            <Disclosure.Button className="hover: flex w-full items-center justify-between  p-2">
                              <span className="text-lg font-medium ">Industry</span>
                              <span className="ml-6 flex h-7 items-center">
                                <FiChevronDown
                                  className={clsx(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </legend>
                          <Disclosure.Panel className="px-4 pb-2 pt-4">
                            <div className="space-y-6">
                              {industries.map((option, optionIdx) => (
                                <div key={option} className="flex items-center">
                                  <input
                                    id={`${optionIdx}-mobile`}
                                    checked={industryFilter.includes(option)}
                                    type="checkbox"
                                    className="rounded"
                                    onChange={() =>
                                      setIndustryFilter(prev =>
                                        prev.includes(option) ? prev.filter(i => i !== option) : [...prev, option],
                                      )
                                    }
                                  />
                                  <label htmlFor={`${optionIdx}-mobile`} className="ml-3 text-base ">
                                    {option}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </fieldset>
                      )}
                    </Disclosure>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <aside className="lg:col-span-3 xl:col-span-2">
          <h2 className="sr-only">Filters</h2>
          <div className="top-35 sticky hidden lg:block">
            <form className="divide-gray-200 space-y-10 divide-y">
              <div>
                <fieldset>
                  <legend className="block text-lg font-medium ">Industry</legend>
                  <div className="space-y-3 pt-6">
                    {industries.map((option, optionIdx) => (
                      <div key={option} className="flex items-center">
                        <input
                          id={`cat-${optionIdx}`}
                          checked={industryFilter.includes(option)}
                          type="checkbox"
                          className="rounded "
                          onChange={() =>
                            setIndustryFilter(prev =>
                              prev.includes(option) ? prev.filter(i => i !== option) : [...prev, option],
                            )
                          }
                        />
                        <label htmlFor={`cat-${optionIdx}`} className="ml-3 text-base">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </form>
          </div>
        </aside>
        <div className="mx-auto grid gap-sm sm:grid-cols-2 lg:col-span-9 xl:col-span-10 xl:grid-cols-3">
          {loading
            ? null
            : filteredPartners?.map(partner => partner && <PartnerCard key={partner?.sys.id} partner={partner} />)}
        </div>
      </div>
    </div>
  );
};

export default PartnerListing;
