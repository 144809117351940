"use client";

import clsx from "clsx";
import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Database_24px from "icons/Database_24px";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import datsourceLottieJson from "lottie/datasource.json";
import { useLayoutEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function HomepageDatasource({
  componentHeading,
  assets,
}: {
  componentHeading: ComponentHeadingFragment | any;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const [play, setPlay] = useState(false);
  const [goTo, setGoTo] = useState<number | undefined>(undefined);

  const { ref: lottieInViewRef } = useInView({
    threshold: [0, 1],
    onChange(inView, entry) {
      if (inView && entry.intersectionRatio === 1) {
        setPlay(true);
        setGoTo(undefined);
      } else if (!inView) {
        setPlay(false);
        setGoTo(0);
      }
    },
  });

  const sectionRef = useRef<any>(null);
  const tl = useRef<any>(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 75%",
          },
        })
        .to(".icon-trail", {
          y: 0,
          duration: 0.6,
          ease: "power2.out",
        })
        .to(
          ".icon",
          {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 0.3,
            ease: "power2.out",
          },
          "< +=0.4",
        )
        .to(
          ".icon-label",
          {
            opacity: 1,
            scale: 1,
            duration: 0.4,
          },
          "< +=0.2",
        );
      ScrollTrigger.create({
        trigger: sectionRef.current,
        onLeaveBack: () => tl.current?.pause(0),
      });
    }, sectionRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className="relative overflow-clip text-center">
      <div className="relative z-10" ref={sectionRef}>
        <div
          className="icon-trail mx-auto h-xl w-[2px] -translate-y-full"
          style={{
            backgroundImage: "linear-gradient(0deg, #7441F7 70.31%, rgba(116, 65, 246, 0) 100%)",
          }}
        ></div>
        <div className="icon z-10 mx-auto mb-xs flex h-md w-md scale-0 justify-center rounded-full bg-icon-gradient p-3xs opacity-0 xl:h-lg xl:w-lg xl:p-2xs">
          <Database_24px strokewidth="1.5" fill="#fff" />
        </div>
        <span
          className={clsx(
            "icon-label z-10 mb-sm block -scale-0 bg-gradient-to-br from-primary-light-300 to-primary-light-500 font-display text-lg font-bold xl:text-xl",
          )}
          style={{
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            textShadow: "0px 0px 40px rgba(145, 102, 255, 0.5)",
          }}
        >
          {componentHeading.eyebrow}
        </span>
        <ComponentHeading {...componentHeading} eyebrow={null} />
      </div>
      <div ref={lottieInViewRef} className="relative h-[250px] w-full sm:h-[300px] lg:h-[400px] 2xl:h-[440px]">
        <Lottie
          className="absolute -top-[10%] left-0 h-full w-full"
          animationData={datsourceLottieJson}
          goTo={goTo}
          play={play}
          loop={false}
        />
      </div>
    </div>
  );
}
