'use client';

import CtaLink from "components/CtaLink";
import Hyperlink from "components/Hyperlink";
import Icon from "components/Icon";
import PricingCard from "components/PricingCardDeck/PricingCard/PricingCard";
import typography from "components/typography";
import { componentPricingCardFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentPricingCardDeckFragment } from "contentful/gql/graphql";
import Link from "next/link";
import { cn } from "utils/functions";

const PricingCardDeck = ({ pricingCardCollection }: ComponentPricingCardDeckFragment) => {
  const cards = pricingCardCollection?.items;
  return (
    <div className="mx-auto">
      <div className="mx-auto grid gap-sm lg:grid-cols-3 lg:gap-sm xl:gap-md">
        {cards?.map((card) => {
          const pricingCard = getFragmentData(componentPricingCardFragment, card);
          return pricingCard && <PricingCard key={pricingCard?.sys.id} {...pricingCard} />;
        })}
      </div>
      <div className="mb-md mt-xs text-center">
        <Hyperlink
          href="#plan-comparison"
          className="group relative inline-flex items-baseline justify-center text-sm text-primary-dark-900"
        >
          <span className="pointer-events-none absolute left-0 top-full h-[1px] w-full origin-[100%_50%] scale-x-0 bg-current transition-transform duration-300 group-hover:origin-[0%_50%] group-hover:scale-x-100"></span>
          <span>See how our plans compare </span>
          {/* <span className="block h-2xs w-2xs">
            <Icon url="/assets/icons/arrow-down-glyph-16.svg" />
          </span> */}
        </Hyperlink>
      </div>
      <div className="rounded-xl bg-secondary-light-100/60 px-6 py-6">
        <div className="flex flex-col items-start md:items-center">
          <h2 className={cn(typography.heading.secondaryComponentHeading, "mb-4xs font-medium")} id="tier-standard">
            Community
          </h2>
          <div className="mb-xs text-sm text-primary-dark-900/70">
            Our open-source low code application platform, available for free.
          </div>
          <CtaLink
            label={"Read our docs"}
            link={"https://docs.appsmith.com/getting-started/setup/installation-guides"}
            openInNewWindow={true}
            variant={"link"}
            size={"medium"}
            extendClassNames="hover:cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default PricingCardDeck;
