import React from 'react';
import { IconProps } from './IconProps';

function YoutubeLogo_32px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'YoutubeLogo 32px';

  return (
    <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill}>
        <path
          d="M31.7 9.6s-.3-2.2-1.3-3.2c-1.2-1.3-2.6-1.3-3.2-1.4-4.5-.3-11.2-.3-11.2-.3s-6.7 0-11.2.3c-.6.1-2 .1-3.2 1.4C.6 7.4.3 9.6.3 9.6S0 12.2 0 14.8v2.4c0 2.6.3 5.2.3 5.2s.3 2.2 1.3 3.2c1.2 1.3 2.8 1.2 3.5 1.4 2.6.2 10.9.3 10.9.3s6.7 0 11.2-.3c.6-.1 2-.1 3.2-1.4 1-1 1.3-3.2 1.3-3.2s.3-2.6.3-5.2v-2.4c0-2.6-.3-5.2-.3-5.2zm-19 10.6v-9l8.6 4.5-8.6 4.5z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default YoutubeLogo_32px;
