import GradiantCardOutline from "components/Cards/GradiantCard/GradiantCardOutline";
import { cn } from "utils/functions";
import typography from "components/typography";
import StackedCompareTable from "./StackedCompareTable";
import Icon from "components/Icon";
import LinkHeading from "components/ComponentHeading/LinkHeading";

type ElementContent = {
  title: string;
  content: {
    icon: string;
    label: string;
  }[];
};

export default function LowCodeNoCodoContent({
  json,
}: {
  json: {
    componentType: string;
    content: {
      elementType: string;
      title: string | undefined;
      body: string | undefined;
      cardType: string | undefined;
      elementLink?: string;
      elementContent: ElementContent[] | undefined;
    }[];
  };
}) {
  return (
    <div className={cn("flex flex-col gap-md")}>
      {json.content?.map((item, index) => {
        switch (item.elementType) {
          case "heading":
            return (
              <div>
                <LinkHeading
                  headingType={"H2"}
                  heading={item.title ?? ""}
                  href={item.elementLink ?? ""}
                  headingAdditionalClass="group-hover:underline group-hover:text-tertiary-pink-500 inline-block mb-4"
                  iconName="arrow"
                  iconColorClass="text-primary-neutral-50"
                  textAlign="text-left"
                />
                {item.body && <p className="text-primary-neutral-50">{item.body}</p>}
              </div>
            );
          case "comparison-list":
            const { cardType } = item;
            return (
              <div key={index}>
                <GradiantCardOutline
                  backgroundGradientClass={cn(
                    "p-[1px]",
                    cardType === "dark"
                      ? "rounded-lg bg-gradient-to-b from-primary-dark-500 to-primary-dark-500/0"
                      : "rounded-lg bg-gradient-to-b from-tertiary-pink-500 to-tertiary-pink-500/0",
                  )}
                  cardMainBackgroundClass={cn(
                    cardType === "dark" ? "bg-primary-dark-900" : "bg-tertiary-pink-500",
                    "rounded-lg",
                  )}
                  cardSecondaryBackgroundClass={cn(
                    "rounded-lg p-md lg:p-lg",
                    cardType === "dark" ? "bg-primary-neutral-50/[0.04]" : "bg-primary-neutral-50/[0.9]",
                  )}
                >
                  <div className="flex flex-col gap-md">
                    <StackedCompareTable json={item.elementContent ?? []} />
                  </div>
                </GradiantCardOutline>
              </div>
            );
        }
      })}
    </div>
  );
}
