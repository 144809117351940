import GradiantCard from "components/Cards/GradiantCard/GradiantCard";
import LinkHeading from "components/ComponentHeading/LinkHeading";
import typography from "components/typography";
import { cn } from "utils/functions";
type WhyLowCodeProps = {
  elementPosition: string | undefined;
  elementType: string | undefined;
  elementContentType: string | undefined;
  elementTitle: string | undefined;
  elementContent: any[];
  elementLink?: string;
};
export default function WhyLowCode({ json, type }: { json: WhyLowCodeProps[] | any; type: string }) {
  return (
    <div className={"grid lg:grid-cols-2 lg:gap-lg lg:py-md"}>
      {json.map((item: any, index: number) => {
        if (item.elementType === "cards") {
          return (
            <div className="max-lg:py-xs" key={index}>
              <div className="flex flex-col gap-sm lg:gap-md">
                {item.elementContent.map((card: any, index: number) => {
                  return <GradiantCard {...card} key={index} />;
                })}
              </div>
            </div>
          );
        }
        return (
          <div className="" key={index}>
            <LinkHeading
              headingType={"H2"}
              heading={item.elementTitle ?? ""}
              href={item.elementLink ?? ""}
              headingAdditionalClass="group-hover:underline group-hover:text-tertiary-pink-500 inline-block"
              iconName="arrow"
              iconColorClass="text-primary-neutral-50"
              textAlign="text-left"
            />
            {item?.elementContent.map((content: any, index: number) => {
              return (
                <p className={cn(typography.body.md, "pt-sm font-light")} key={index}>
                  {content?.text}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
