"use client";

import { JumplistProvider } from "@faceless-ui/jumplist";
import {
  AssetFragment,
  ComponentHeadingFragment,
  ComponentCustomJsonOnlyFragment,
  ComponentTestimonialFragment,
} from "contentful/gql/graphql";
import Jumplist from "components/Jumplist/JumplistLowCode";

export default function LowCodePage({
  json,
  componentHeading,
  assets,
  jsonComponents,
  testimonials,
}: {
  json: {
    headingId: string;
    headingBackgroundImageRef: string;
    tabs: { sectionTitle: string; sectionId: string; testimonialId: string | undefined }[];
  };
  componentHeading: ComponentHeadingFragment | any;
  assets: (AssetFragment | undefined)[] | undefined;
  jsonComponents: (ComponentCustomJsonOnlyFragment | undefined)[] | undefined;
  testimonials: (ComponentTestimonialFragment | undefined)[] | undefined;
}) {
  return (
    <div>
      <div className="flex flex-col items-center">
        <Jumplist
          json={json}
          componentHeading={componentHeading}
          assets={assets}
          jsonComponents={jsonComponents}
          testimonials={testimonials}
        />
      </div>
    </div>
  );
}
