import { IconProps } from './IconProps';

function Database_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'Database 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill} stroke={fill}>
        <path
          d="M3 4v16c0 1.7 4 3 9 3s9-1.3 9-3V4"
          fill="none"
          stroke={fill}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M21 12c0 1.7-4 3-9 3s-9-1.3-9-3"
          fill="none"
          stroke={fill}
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <ellipse
          cx="12"
          cy="4"
          fill="none"
          rx="9"
          ry="3"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
}

export default Database_24px;
