import Icon from "components/Icon";
import typography from "components/typography";
import { cn } from "utils/functions";

export default function ListItemIconText({
  clName,
  icon,
  text,
  textClass = null,
  iconClass = null,
}: {
  clName: string;
  icon: string;
  text: string;
  textClass: string | null;
  iconClass: string | null;
}) {
  return (
    <div className={clName}>
      <div className={cn("relative top-[2px] h-xs min-h-xs w-xs min-w-xs text-tertiary-pink-500", iconClass)}>
        <Icon url={`/assets/icons/${icon}`} />
      </div>
      <div className={cn(typography.body.xs, "font-normal", textClass)}>{text}</div>
    </div>
  );
}
