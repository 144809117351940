"use client";

import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import Icon from "components/Icon";
import typography from "components/typography";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { cn } from "utils/functions";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useLayoutEffect } from "react";

interface Node {
  icon: string;
  heading: string;
  body: string;
}

export default function WhyAppsmithTheAppsmithSolution({
  componentHeading,
  json,
  assets,
}: {
  componentHeading: ComponentHeadingFragment | undefined;
  json: { nodes: Node[] };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const bgImg = assets?.find((asset) => asset?.title === "the-appsmith-solution-bg.svg");

  const sectionRef = useRef<any>(null);
  const tl = useRef<any>([]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      const nodes = document.querySelectorAll(".node");
      nodes.forEach((node) => {
        tl.current.push(
          gsap
            .timeline({
              scrollTrigger: {
                trigger: node,
                start: "top 85%",
              },
            })
            .to(node.querySelector(".icon"), {
              scale: 1,
              opacity: 1,
              duration: 0.3,
            })
            .to(
              node.querySelector(".icon-label"),
              {
                opacity: 1,
                scale: 1,
                duration: 0.4,
              },
              "<",
            )
            .to(
              node.querySelector(".icon-trail"),
              {
                scaleY: 1,
                duration: 0.6,
              },
              "<",
            ),
        );
      });
    }, sectionRef);

    tl.current.forEach((timeline: GSAPTimeline) => {
      ScrollTrigger.create({
        trigger: timeline.scrollTrigger?.trigger,
        onLeaveBack: () => timeline.pause(0),
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef}>
      <div className="hidden md:block">{componentHeading && <ComponentHeading {...componentHeading} />}</div>
      <div className="grid auto-rows-auto md:auto-rows-auto">
        <div className="node relative mt-sm flex h-[320px] w-full justify-start md:h-[240px] md:justify-center xl:mt-md">
          <div className="icon relative z-10 h-lg w-lg flex-shrink-0 scale-0 rounded-full bg-icon-gradient p-2xs text-primary-light-50 opacity-0">
            <Icon url="/assets/icons/check-glyph-24.svg" />
          </div>
          <div className="mb-md ml-xs block md:hidden">
            {componentHeading && <ComponentHeading {...componentHeading} alignment={"left"} />}
          </div>

          {bgImg && (
            <Image
              className="absolute left-[23px] top-[17px] h-auto w-full -translate-x-1/2 -translate-y-[37.2%] animate-pulse md:left-1/2 md:top-0"
              src={bgImg.url ?? ""}
              alt={""}
              width={bgImg.width ?? 0}
              height={bgImg.height ?? 0}
            />
          )}
          <div
            className="icon-trail absolute left-[23px] top-[48px] mx-auto h-[100%] w-[2px] origin-top scale-y-0 md:left-1/2 md:-translate-x-1/2"
            style={{
              backgroundImage: "linear-gradient(0deg, #7441F7 60.31%, rgba(116, 65, 246, 0) 100%)",
            }}
          ></div>
        </div>
        {json?.nodes?.map((node, index) => {
          return (
            <div
              key={index}
              className="node relative grid grid-cols-[1fr_auto_1fr] gap-x-xs pb-md last:pb-0 md:odd:text-right md:even:text-left lg:gap-x-sm"
            >
              {index !== json.nodes.length - 1 && (
                <div
                  className="icon-trail absolute left-[23px] top-[48px] mx-auto h-[100%] w-[2px] origin-top scale-y-0 md:left-1/2 md:-translate-x-1/2"
                  style={{
                    backgroundImage: "linear-gradient(0deg, #7441F7 60.31%, rgba(116, 65, 246, 0) 100%)",
                  }}
                ></div>
              )}
              <div className={cn("hidden md:block", index % 2 === 0 ? "order-first" : "order-last")}></div>
              <div
                className={
                  "icon h-lg w-lg flex-shrink-0 scale-0 rounded-full bg-icon-gradient p-2xs text-primary-light-50 opacity-0"
                }
              >
                <Icon url={`/assets/icons/${node.icon}`} />
              </div>
              <div
                className={cn(
                  "icon-label origin-left scale-0 space-y-3xs opacity-0 xl:space-y-2xs",
                  index % 2 === 0 ? "origin-left md:order-last" : "md:order-first md:origin-right",
                )}
              >
                <h3
                  className={cn(
                    "text-primary-dark-900 dark:text-primary-neutral-50",
                    typography.heading.secondaryComponentHeading,
                  )}
                >
                  {node.heading}
                </h3>
                <dd
                  className={cn("text-primary-dark-700 dark:text-primary-dark-100/80", typography.body.cardBody)}
                  dangerouslySetInnerHTML={{
                    __html: node.body,
                  }}
                ></dd>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
