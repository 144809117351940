"use client";

import clsx from "clsx";
import CtaLink from "components/CtaLink";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DiscordLogo_32px from "icons/DiscordLogo_32px";
import GithubLogo_32px from "icons/GithubLogo_32px";
import YoutubeLogo_32px from "icons/YoutubeLogo_32px";
import Image from "next/image";
import { useLayoutEffect, useRef } from "react";

export interface CommunityGridConfigType {
  items: Item[];
}

interface Item {
  width: string;
  heading: string;
  body: string;
  image: string;
  footer: Footer;
}

interface Footer {
  text?: string;
  links?: Link[];
  link?: Link;
}

interface Link {
  label: string;
  href: string;
}

const CommunityCard = ({ item, image, index }: { item: Item; image?: AssetFragment; index: number }) => {
  const { width, heading, body, footer } = item;

  const cardRef = useRef<any>(null);
  const tl = useRef<any>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: cardRef.current,
            start: "top 120%",
          },
        })
        .to(cardRef.current, {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 0.75,
          delay: index !== 0 && index % 2 === 1 ? 0 : 0.1,
        });
      ScrollTrigger.create({
        trigger: cardRef.current,
        start: "top 120%",
        onLeaveBack: () => tl.current?.pause(0),
      });
    }, cardRef.current);
  }, [index]);

  return (
    <div
      className={clsx(
        width === "full" && "dark col-span-2",
        width === "half" && "col-span-2 md:col-span-1",
        "relative translate-y-1/4 rounded-lg bg-secondary-light-100/60 p-sm opacity-0 xl:p-md",
      )}
      ref={cardRef}
    >
      {image && (
        <Image
          src={image.url ?? ""}
          height={image.height ?? 0}
          width={image.width ?? 0}
          alt={image.title ?? "Community image"}
          className="absolute left-0 top-0 h-full w-full rounded-lg object-cover"
        />
      )}
      <div className="relative flex h-full flex-col justify-between">
        <div>
          <h3
            className={clsx(
              width === "full" && typography.heading.componentHeading,
              width === "half" && typography.heading.innerSubheading,
              "mb-2xs text-primary-dark-800 dark:text-primary-neutral-50",
            )}
          >
            {heading}
          </h3>
          <p
            className={clsx(
              typography.body.cardBody,
              "text-primary-dark-800 dark:text-primary-neutral-50/90",
              width === "full" && "md:w-1/2",
            )}
          >
            {body}
          </p>
        </div>
        <footer className="mt-md">
          {footer.text && (
            <p
              className={clsx(
                "font-body text-xs font-medium leading-snug text-primary-dark-800 dark:text-primary-neutral-50/90 sm:text-sm xl:text-base",
              )}
            >
              {footer.text}
            </p>
          )}
          {footer.link && (
            <div className="flex">
              <CtaLink
                variant={"Link"}
                size={"Medium"}
                label={footer.link.label}
                link={footer.link.href}
                endIcon={"Arrow"}
              />
            </div>
          )}
          {footer.links?.length && (
            <div className="grid grid-cols-3 gap-2xs lg:grid-cols-6 2xl:grid-cols-7">
              {footer.links.map((linkItem) => {
                if (linkItem.label?.toLowerCase().includes("youtube")) {
                  return (
                    <Hyperlink
                      key={linkItem.label}
                      href={linkItem.href}
                      aria-label={linkItem.label}
                      className="h-lg w-full rounded bg-tertiary-red-500 p-2xs transition-opacity hover:opacity-60"
                    >
                      <YoutubeLogo_32px fill="#fff" />
                    </Hyperlink>
                  );
                }
                if (linkItem.label?.toLowerCase().includes("github")) {
                  return (
                    <Hyperlink
                      key={linkItem.label}
                      href={linkItem.href}
                      aria-label={linkItem.label}
                      className="h-lg w-full rounded bg-secondary-dark-700 p-2xs transition-opacity hover:opacity-60"
                    >
                      <GithubLogo_32px fill="#fff" />
                    </Hyperlink>
                  );
                }
                if (linkItem.label?.toLowerCase().includes("discord")) {
                  return (
                    <Hyperlink
                      key={linkItem.label}
                      href={linkItem.href}
                      aria-label={linkItem.label}
                      className="h-lg w-full rounded bg-tertiary-purple-500 p-2xs transition-opacity hover:opacity-60"
                    >
                      <DiscordLogo_32px fill="#fff" />
                    </Hyperlink>
                  );
                }
                if (linkItem.label?.toLowerCase().includes("community")) {
                  return (
                    <CtaLink
                      key={linkItem.label}
                      label={linkItem.label}
                      link={linkItem.href}
                      variant={"Primary"}
                      endIcon={"Arrow"}
                      size={"Medium"}
                      extendClassNames="col-span-3 h-lg text-base xl:text-lg"
                    />
                  );
                }
                if (linkItem.label?.toLowerCase().includes("parter")) {
                  return (
                    <CtaLink
                      key={linkItem.label}
                      label={linkItem.label}
                      link={linkItem.href}
                      variant={"Link"}
                      endIcon={"Arrow"}
                      size={"Medium"}
                      extendClassNames="col-span-3 h-lg text-base xl:text-lg"
                    />
                  );
                }
              })}
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

export default function HomepageCommunityGrid({
  json,
  assets,
}: {
  json: CommunityGridConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <div className="grid grid-cols-2 gap-sm">
      {json.items.map((item, index) => {
        const image = assets?.find((asset) => asset?.title === item.image);
        return <CommunityCard item={item} index={index} key={index} image={image} />;
      })}
    </div>
  );
}
