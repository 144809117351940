'use client';

import CaseStudyCard, { FeaturedCaseStudyCard } from "components/Cards/CaseStudyCard";
import Icon from "components/Icon";
import typography from "components/typography";
import { templateCaseStudyListingFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateCaseStudyListingFragment } from "contentful/gql/graphql";
import { useEffect, useRef, useState } from "react";

const CaseStudyListing = ({ caseStudies }: { caseStudies: (TemplateCaseStudyListingFragment | null)[] }) => {
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [industryFilter, setIndustryFilter] = useState<string>("");
  const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudies);
  const [page, setPage] = useState<number>(1);

  const perCount = 3;

  const categories: string[] = caseStudies.reduce((acc, caseStudy) => {
    if (caseStudy?.category && !acc.includes(caseStudy?.category)) {
      acc.push(caseStudy.category);
    }
    return acc;
  }, [] as string[]);
  const industries: string[] = caseStudies.reduce((acc, caseStudy) => {
    if (caseStudy?.industry && !acc.includes(caseStudy?.industry)) {
      acc.push(caseStudy.industry);
    }
    return acc;
  }, [] as string[]);

  const featuredCaseStudy = filteredCaseStudies.find((caseStudy) => caseStudy?.isFeatured === true);

  useEffect(() => {
    let modifiedCaseStudyListByFilter = caseStudies;
    if (categoryFilter || industryFilter) {
      modifiedCaseStudyListByFilter = caseStudies.filter((caseStudy) => {
        if (categoryFilter && industryFilter) {
          return caseStudy?.category === categoryFilter && caseStudy?.industry === industryFilter;
        } else if (categoryFilter) {
          return caseStudy?.category === categoryFilter;
        } else if (industryFilter) {
          return caseStudy?.industry === industryFilter;
        }
        return true;
      });
    }
    setFilteredCaseStudies(modifiedCaseStudyListByFilter);
    setPage(1);
  }, [categoryFilter, industryFilter, caseStudies]);

  return (
    <div>
      <div className="mb-sm text-center lg:mb-md">
        <h2 className={typography.heading.sectionHeading}>Case Studies</h2>
      </div>

      {/* <div className="mb-sm flex space-x-sm">
        <div className="relative">
          <label htmlFor="category" className="sr-only block text-lg font-medium">
            Use Case
          </label>
          <select
            id="category"
            className="appearance-none rounded-md border border-primary-light-100 bg-primary-neutral-50 px-xs py-3xs font-medium text-primary-light-500"
            onChange={(e) => setCategoryFilter(e.target.value)}
          >
            <option value="">Use case</option>
            {categories.map((option, optionIdx) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className="absolute right-xs top-1/2 flex h-2xs w-2xs -translate-y-1/2 text-primary-light-500">
            <Icon url="/assets/icons/triangle-down-glyph-12.svg" />
          </div>
        </div>
        <div className="relative">
          <label htmlFor="industry" className="sr-only block text-lg font-medium">
            Industry
          </label>
          <select
            id="industry"
            className="appearance-none rounded-md border border-primary-light-100 bg-primary-neutral-50 px-xs py-3xs pr-xl font-medium text-primary-light-500 marker:hidden"
            onChange={(e) => setIndustryFilter(e.target.value)}
          >
            <option value="">Industry</option>
            {industries.map((option, optionIdx) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className="absolute right-xs top-1/2 h-2xs w-2xs -translate-y-1/2 text-primary-light-500">
            <Icon url="/assets/icons/triangle-down-glyph-12.svg" />
          </div>
        </div>
      </div> */}
      <div className="">
        <div className="mx-auto grid gap-sm sm:grid-cols-2 lg:grid-cols-3">
          {featuredCaseStudy && (
            <div className="hidden sm:col-span-2 sm:block lg:col-span-3">
              <FeaturedCaseStudyCard caseStudy={featuredCaseStudy} useStandardImage={false} />
            </div>
          )}
          {featuredCaseStudy && (
            <div className="sm:hidden">
              <CaseStudyCard {...featuredCaseStudy} />
            </div>
          )}
          {filteredCaseStudies &&
            filteredCaseStudies
              .filter((caseStudy) => caseStudy?.sys.id !== featuredCaseStudy?.sys.id)
              // .slice(0, page * perCount)
              .map((caseStudyFragment, index) => {
                if (!caseStudyFragment) return null;
                return <CaseStudyCard {...caseStudyFragment} key={caseStudyFragment?.sys.id} index={index} />;
              })}
        </div>
        {/* {page * perCount < filteredCaseStudies.length && (
          <div className="mt-md flex justify-center text-center">
            <button
              className="rounded-md border border-primary-light-500 px-xs py-3xs font-display font-bold text-primary-light-500 transition-colors hover:bg-primary-light-50"
              onClick={() => setPage(page + 1)}
            >
              Load more
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CaseStudyListing;
