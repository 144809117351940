"use client";

import clsx from "clsx";
import ProgressBar from "components/ProgressBar";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { cn } from "utils/functions";

export interface StackedShowcaseConfigType {
  viewMoreLink: ViewMoreLink | undefined;
  autoplay: boolean;
  elementContent: UseCase[];
  elementType: string | undefined;
  elementPosition: string | undefined;
  elementHeading: string | undefined;
}

interface ViewMoreLink {
  label: string;
  href: string;
  hasIcon: boolean;
}

interface UseCase {
  label: string;
  imageAssetReferenceId: string | undefined;
  videoAssetReferenceId: string;
  useCasePosition: string | undefined;
  useCaseDescription: string | undefined;
  useCaseButtonLink: string | undefined;
  useCaseButtonLabel: string | undefined;
  autoplayDuration: number | undefined; // Duration in seconds
}

export default function StackedShowcase({
  json,
  assets,
}: {
  json: StackedShowcaseConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const { viewMoreLink, autoplay, elementContent } = json;
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Calculate autoplay speed based on the useCase's autoplayDuration
  const getAutoplaySpeed = useCallback(() => {
    const currentUseCase = elementContent[selectedIndex];
    return currentUseCase.autoplayDuration;
  }, [elementContent, selectedIndex]);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    { align: "start" },
    autoplay ? [Autoplay({ delay: getAutoplaySpeed(), stopOnInteraction: true })] : [],
  );

  const onHeadingClick = useCallback(
    (index: number) => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1024) {
        setSelectedIndex(index);
      } else {
        if (!emblaMainApi) return;
        emblaMainApi.plugins().autoplay?.stop();
        emblaMainApi.scrollTo(index);
      }
    },
    [emblaMainApi, setSelectedIndex],
  );

  const onSelect = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1024) {
      // setSelectedIndex(index);
    } else {
      if (!emblaMainApi) return;
      setSelectedIndex(emblaMainApi.selectedScrollSnap());
    }
  }, [emblaMainApi, setSelectedIndex]);

  const onComplete = useCallback(() => {
    if (selectedIndex === elementContent.length - 1) {
      onHeadingClick(0);
    } else {
      onHeadingClick(selectedIndex + 1);
    }
  }, [onHeadingClick, selectedIndex, elementContent]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <div className="">
      {json?.elementHeading && (
        <div className="pb-sm">
          <h3 className={cn(typography.heading.innerSubheading)}>{json?.elementHeading}</h3>
        </div>
      )}
      <div className="">
        <div className="mb-sm justify-items-center gap-sm">
          <div className="relative grid gap-sm lg:grid-cols-2">
            <div className="">
              {elementContent.map((useCase, index) => {
                const asset = assets?.find((asset) => asset?.sys.id === useCase.imageAssetReferenceId);
                const assetVideo = assets?.find((asset) => asset?.sys.id === useCase.videoAssetReferenceId);
                return (
                  <div
                    className="relative gap-sm lg:grid-cols-1 lg:pr-lg"
                    key={useCase.label + "-tab-" + index}
                    onClick={() => onHeadingClick(index)}
                  >
                    <div
                      className={clsx(
                        typography.label,
                        "relative z-10 cursor-pointer border-b-[1px] border-primary-neutral-50/20 py-sm font-display transition-colors duration-300",
                        selectedIndex === index && "!font-medium text-primary-neutral-50 delay-150",
                        selectedIndex !== index && "font-light text-primary-neutral-200",
                      )}
                      aria-label={useCase.label}
                    >
                      {useCase.label}

                      {selectedIndex === index && (
                        <div className="absolute -bottom-[1px] left-0 h-[1px] w-full">
                          <ProgressBar
                            duration={useCase.autoplayDuration || 0}
                            styleClass="border-b-[1px] border-primary-neutral-50 transition-transform duration-300 ease-in-out will-change-transform"
                            onComplete={onComplete}
                          />
                        </div>
                      )}
                    </div>

                    {selectedIndex === index && (
                      <div className="mx-auto overflow-hidden pt-xs lg:hidden">
                        <div className="flex space-x-sm">
                          <div
                            key={useCase.label + "-content-" + index}
                            className={cn(
                              "relative flex h-auto w-full min-w-0 flex-[0_0_100%] object-contain object-top transition-opacity duration-1000 max-lg:flex-col-reverse",
                              selectedIndex === index && "opacity-100",
                              selectedIndex !== index && "opacity-0",
                              useCase.useCasePosition === "left" && "flex-row",
                              useCase.useCasePosition === "right" && "flex-row-reverse",
                            )}
                          >
                            {useCase.useCaseDescription && (
                              <div className="flex flex-[0_0_30%] flex-col justify-center p-xs max-lg:mb-sm max-lg:items-center max-lg:p-3xs lg:pr-lg_2">
                                <div className="font-display text-xl font-bold max-lg:flex-row-reverse max-lg:text-xs">
                                  {useCase.useCaseDescription}
                                </div>
                                {useCase.useCaseButtonLabel && useCase.useCaseButtonLink && (
                                  <div className="md:mt-x mt-xs flex">
                                    <a
                                      className="group relative inline-flex items-center whitespace-nowrap rounded px-sm py-2xs font-display text-xs font-bold text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50 sm:px-sm sm:py-xs lg:text-sm xl:px-md xl:py-xs 2xl:px-lg"
                                      target="_blank"
                                      href={useCase.useCaseButtonLink}
                                    >
                                      <span className="relative z-10 flex items-center justify-center whitespace-nowrap leading-none">
                                        {useCase.useCaseButtonLabel}
                                      </span>
                                      <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
                                      <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
                                    </a>
                                  </div>
                                )}
                              </div>
                            )}
                            {assetVideo?.url ? (
                              <video
                                src={assetVideo?.url + "?fm=mp4"}
                                id={`video-mb-${index}`}
                                // className="mx-auto my-sm h-auto w-full max-w-screen-lg overflow-hidden rounded-lg"
                                className={cn(
                                  "relative mb-xs h-auto w-full min-w-0 flex-[0_0_70%] object-contain object-top transition-opacity duration-1000 max-lg:flex-none",
                                  selectedIndex === index && "opacity-100",
                                  selectedIndex !== index && "opacity-0",
                                )}
                                width={asset?.width || 0}
                                height={asset?.height || 0}
                                muted
                                autoPlay={selectedIndex === index}
                                loop
                                playsInline
                                onEnded={() => {
                                  if (index === elementContent.length - 1) {
                                    onHeadingClick(0);
                                  } else {
                                    onHeadingClick(index + 1);
                                  }
                                }}
                              >
                                <source src={asset?.url + "?fm=mp4"} type="video/mp4" />
                              </video>
                            ) : asset?.url ? (
                              <Image
                                priority={index === 0}
                                className={cn(
                                  "relative h-auto w-full min-w-0 flex-[0_0_70%] object-contain object-top transition-opacity duration-1000",
                                  selectedIndex === index && "opacity-100",
                                  selectedIndex !== index && "opacity-0",
                                )}
                                src={asset?.url || ""}
                                alt={asset?.title || "Showcase image"}
                                width={asset?.width || 0}
                                height={asset?.height || 0}
                                sizes="(min-width: 1280px) 1024px, 768px"
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mx-auto overflow-hidden max-lg:hidden" ref={emblaMainRef}>
              <div className="flex h-full w-full">
                {elementContent.map((useCase, index) => {
                  const asset = assets?.find((asset) => asset?.sys.id === useCase.imageAssetReferenceId);
                  const assetVideo = assets?.find((asset) => asset?.sys.id === useCase.videoAssetReferenceId);
                  return (
                    <div
                      key={useCase.label + "-content-" + index}
                      className={cn(
                        "relative flex h-full w-full min-w-0 flex-[0_0_100%] object-contain object-top transition-opacity duration-1000",
                        selectedIndex === index && "opacity-100",
                        selectedIndex !== index && "opacity-0",
                      )}
                    >
                      {assetVideo?.url ? (
                        <video
                          src={assetVideo?.url + "?fm=mp4"}
                          id={`video-${index}`}
                          // className="mx-auto my-sm h-auto w-full max-w-screen-lg overflow-hidden rounded-lg"
                          className={cn(
                            "relative mb-xs h-auto w-full min-w-0 object-contain object-top transition-opacity duration-1000 max-lg:flex-none",
                            selectedIndex === index && "opacity-100",
                            selectedIndex !== index && "opacity-100",
                          )}
                          width={asset?.width || 0}
                          height={asset?.height || 0}
                          muted
                          autoPlay={selectedIndex === index}
                          loop
                          playsInline
                          onEnded={() => {
                            if (index === elementContent.length - 1) {
                              onHeadingClick(0);
                            } else {
                              onHeadingClick(index + 1);
                            }
                          }}
                        >
                          <source src={asset?.url + "?fm=mp4"} type="video/mp4" />
                        </video>
                      ) : asset?.url ? (
                        <Image
                          priority={index === 0}
                          className={cn(
                            "relative h-full w-full min-w-0  object-contain object-top transition-opacity duration-1000",
                            selectedIndex === index && "opacity-100",
                            selectedIndex !== index && "opacity-0",
                          )}
                          src={asset?.url || ""}
                          alt={asset?.title || "Showcase image"}
                          width={asset?.width || 0}
                          height={asset?.height || 0}
                          sizes="(min-width: 1280px) 1024px, 768px"
                        />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
