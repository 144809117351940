"use client";
import { JumplistNode, useJumplist, JumplistButton, JumplistProvider } from "@faceless-ui/jumplist";
import {
  AssetFragment,
  ComponentCustomJsonOnlyFragment,
  ComponentHeadingFragment,
  ComponentTestimonialFragment,
} from "contentful/gql/graphql";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import typography from "components/typography";
import "./Jumplist.css";
import ComponentHeading from "components/ComponentHeading/ComponentHeadingV2";
import Image from "next/image";
import TestimonialV2 from "components/TestimonialV2";
import { cn } from "utils/functions";
import { sectionPaddingBottomClass, sectionPaddingTopClass } from "utils/sectionSpacingConstants";
import LowCodeForDevs from "components/ComponentCustomJSON/LowCodePage/LowCodeForDevs";
import LowCodeNoCode from "components/ComponentCustomJSON/LowCodePage/LowCodeNoCode";
import LowCodeCommercialSaaS from "components/ComponentCustomJSON/LowCodePage/LowCodeCommercialSaaS";
import LowCodeWhatWorksWhen from "components/ComponentCustomJSON/LowCodePage/LowCodeWhatWorksWhen";
import LowCodeMythTruth from "components/ComponentCustomJSON/LowCodePage/LowCodeMythTruth";
import LowCodeDevOps from "components/ComponentCustomJSON/LowCodePage/LowCodeDevOps";
import LowCodeBuyerGuide from "components/ComponentCustomJSON/LowCodePage/LowCodeBuyerGuide";
import LowCodeContactForm from "components/SingleInstance/LowCodeContactForm";

export default function JumplistLowCode({
  json,
  componentHeading,
  assets,
  jsonComponents,
  testimonials,
}: {
  json: {
    headingId: string;
    headingBackgroundImageRef: string;
    tabs: { sectionTitle: string; sectionId: string; testimonialId: string | undefined }[];
  };
  componentHeading: ComponentHeadingFragment | any;
  assets: (AssetFragment | undefined)[] | undefined;
  jsonComponents: (ComponentCustomJsonOnlyFragment | undefined)[] | undefined;
  testimonials: (ComponentTestimonialFragment | undefined)[] | undefined;
}) {
  const jumplist = useJumplist();

  const sectionRef = useRef(null);
  const headingRef = useRef(null);

  const [progress, setProgress] = useState(0);

  const [headerAdditionalSpace, setHeaderAdditionalSpace] = useState(0);

  useLayoutEffect(() => {
    const updateHeaderSpace = () => {
      const banner = document.querySelector(".banner");
      const header = document.getElementById("header");

      let totalHeight = 0;

      if (banner) {
        totalHeight += banner.getBoundingClientRect().height;
      }

      if (header) {
        totalHeight += header.getBoundingClientRect().height;
      }

      setHeaderAdditionalSpace(totalHeight);
    };

    // Initial calculation
    updateHeaderSpace();

    // Set up a ResizeObserver to watch for size changes
    const resizeObserver = new ResizeObserver(updateHeaderSpace);

    const banner = document.querySelector(".banner");
    const header = document.getElementById("header");

    if (banner) resizeObserver.observe(banner);
    if (header) resizeObserver.observe(header);

    // Clean up the observer when the component unmounts
    return () => resizeObserver.disconnect();
  }, []);

  const jumpListScrollToIndex = ({ index, id }: { index: number; id: string }) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -headerAdditionalSpace; // Adjust this value as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    mm.add(
      "(min-width: 768px)",
      (self) => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: `top top+=${headerAdditionalSpace}px`,
          end: "bottom bottom-=120px",
          pin: headingRef.current,
          pinSpacing: false, // Make heading visible when pinned
          onUpdate: (self) => {
            setProgress(parseFloat(self.progress.toFixed(2)));
          },
        });
      },
      sectionRef,
    );
    return () => mm.revert();
  }, [headerAdditionalSpace]);

  useEffect(() => {
    const target = document.querySelector(
      "header#global-header div.dark.relative.z-40.transition-transform.duration-300.ease-out",
    );

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.target instanceof HTMLElement &&
          mutation.attributeName === "class" &&
          mutation.target.classList.contains("hidden")
        ) {
          setHeaderAdditionalSpace(0);
        } else {
          setHeaderAdditionalSpace(54);
        }
      });
    });
    if (target) {
      observer.observe(target, {
        attributes: true,
        attributeFilter: ["style", "class"], // Observe both style and class changes
      });
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  const bgImg = assets?.find((asset) => asset?.sys.id === json.headingBackgroundImageRef);

  return (
    <JumplistProvider rootMargin={`-${headerAdditionalSpace}px 0px 0px 0px`}>
      <div className="relative -mb-sm overflow-x-hidden md:-mb-md xl:-mb-lg">
        <div className="relative flex h-[400px] flex-col justify-between">
          {componentHeading ? (
            <div className="container mx-auto flex h-[300px] flex-col items-center justify-center">
              <div className="z-10">
                <ComponentHeading {...componentHeading} />
              </div>
              {bgImg ? (
                <Image
                  className="absolute bottom-0 right-0 h-[100%] w-[100%] object-fill"
                  alt=""
                  src={bgImg.url ?? ""}
                  height={bgImg.height ?? 0}
                  width={bgImg.width ?? 0}
                  style={{
                    transform: `scale(1.7)`,
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div ref={sectionRef}>
          <div
            className="!left-0 z-20 hidden w-screen overflow-x-hidden bg-primary-neutral-50 lg:block"
            ref={headingRef}
            id="jumplist-pin-spacer"
          >
            <div className="relative flex flex-row justify-evenly gap-4 py-[5px]">
              <div className="absolute top-[5px] h-[1px] w-[90%] bg-gradient-to-r from-primary-dark-50 from-0% via-primary-dark-900 via-50% to-primary-dark-50 to-100%  transition-transform duration-300 ease-in-out will-change-transform"></div>
              <div
                className="absolute left-[5%] top-[5px] h-[3px] bg-gradient-to-r from-tertiary-pink-50 from-0% to-tertiary-pink-500 to-10% transition-transform duration-300 ease-in-out will-change-transform"
                style={{
                  width: `calc(90% * ${progress})`,
                }}
              >
                <div
                  className="absolute -top-[4px] right-0 h-[10px] w-[10px] rounded-full border-2 border-tertiary-pink-500 bg-primary-neutral-50"
                  style={{
                    visibility: progress === 0 ? "hidden" : "visible",
                  }}
                ></div>
              </div>
              {json.tabs.map((tab, index) => {
                return (
                  <JumplistButton
                    key={`tab-${index}`}
                    onClick={() => jumpListScrollToIndex({ index, id: `node-${index}` })}
                  >
                    <div
                      className={clsx(
                        typography.label,
                        "relative z-10 cursor-pointer rounded-md py-xs text-sm !font-normal text-secondary-dark-700 transition-colors duration-300 hover:text-tertiary-pink-500 xl:text-sm",
                        progress !== 0 &&
                          Math.floor((progress * 100) / 14) === index &&
                          "!text-tertiary-pink-500 delay-150",
                      )}
                    >
                      {tab.sectionTitle}
                    </div>
                  </JumplistButton>
                );
              })}
            </div>
          </div>
          <div className="w-screen">
            {json?.tabs.map((tab, index) => {
              const { sectionId, testimonialId } = tab;
              const component = jsonComponents?.find((componentData) => componentData?.sys.id === sectionId);
              const testimonial = testimonials?.find((componentData) => componentData?.sys.id === testimonialId);
              return (
                <JumplistNode key={`node-${index}`} nodeID={`node-${index}`}>
                  <div id={`node-${index}`}>
                    {component?.componentId === "low-code-devs" && (
                      <>
                        <LowCodeForDevs
                          key={component.sys.id}
                          json={component.json}
                          id={component.sys.id}
                          assets={assets}
                        />
                        {testimonial && (
                          <section className="container mx-auto">
                            <div className={cn(sectionPaddingTopClass["Medium"], sectionPaddingBottomClass["Medium"])}>
                              <TestimonialV2 {...testimonial} />
                            </div>
                          </section>
                        )}
                      </>
                    )}
                    {component?.componentId === "low-code-no-code" && (
                      <LowCodeNoCode
                        key={component.sys.id}
                        json={component.json}
                        id={component.sys.id}
                        assets={assets}
                      />
                    )}
                    {component?.componentId === "low-code-saas" && (
                      <LowCodeCommercialSaaS
                        key={component.sys.id}
                        json={component.json}
                        id={component.sys.id}
                        assets={assets}
                      />
                    )}
                    {component?.componentId === "low-code-what-works-when" && (
                      <LowCodeWhatWorksWhen
                        key={component.sys.id}
                        json={component.json}
                        id={component.sys.id}
                        assets={assets}
                      />
                    )}
                    {component?.componentId === "low-code-myth-truth" && (
                      <LowCodeMythTruth
                        key={component.sys.id}
                        json={component.json}
                        id={component.sys.id}
                        assets={assets}
                      />
                    )}
                    {component?.componentId === "low-code-dev-ops" && (
                      <>
                        <LowCodeDevOps
                          key={component.sys.id}
                          json={component.json}
                          id={component.sys.id}
                          assets={assets}
                        />
                        {testimonial && (
                          <section className="container mx-auto">
                            <div className={cn(sectionPaddingTopClass["Medium"], sectionPaddingBottomClass["Medium"])}>
                              <TestimonialV2 {...testimonial} />
                            </div>
                          </section>
                        )}
                      </>
                    )}
                    {component?.componentId === "low-code-buyer-guide" && (
                      <LowCodeBuyerGuide
                        key={component.sys.id}
                        json={component.json}
                        id={component.sys.id}
                        assets={assets}
                      />
                    )}
                  </div>
                </JumplistNode>
              );
            })}
          </div>
        </div>
        <div className="contact-form-container pb-sm md:pb-md xl:pb-lg">
          <div className="contact-form container mx-auto">
            <LowCodeContactForm />
          </div>
        </div>
      </div>
    </JumplistProvider>
  );
}
