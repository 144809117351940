"use client";

import { JumplistNode } from "@faceless-ui/jumplist";
import { useJumplist } from "@faceless-ui/jumplist";
import HomepageCode from "components/ComponentCustomJSON/HomepageCode";
import HomepageDatasource from "components/ComponentCustomJSON/HomepageDatasource";
import HomepageWidgets from "components/ComponentCustomJSON/HomepageWidgets";
import {
  AssetFragment,
  ComponentCustomJsonMetaFragment,
  ComponentHeadingFragment,
  CtaFragment,
} from "contentful/gql/graphql";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import typography from "components/typography";
import "./Jumplist.css";
import HomepageDeploy from "components/ComponentCustomJSON/HomepageDeploy";

export default function Jumplist({
  json,
  componentHeading,
  assets,
}: {
  json: {
    tabs: { title: string; refAssetId: string; refHeadingComponent: string; componentType: string }[];
    ctaButton: { ctaButtonLink: string; ctaButtonText: string };
  };
  componentHeading: (ComponentHeadingFragment | undefined)[] | undefined;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const jumplist = useJumplist();

  const sectionRef = useRef(null);
  const headingRef = useRef(null);

  const [progress, setProgress] = useState(0);
  const [headerAdditionalSpace, setHeaderAdditionalSpace] = useState(54);

  const jumpListScrollToIndex = ({ index, id }: { index: number; id: string }) => {
    jumplist.scrollToID(id);
    return null;
  };

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    mm.add(
      "(min-width: 768px)",
      (self) => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: `top top+=${headerAdditionalSpace + 66}px`,
          end: "bottom bottom-=120px",
          pin: headingRef.current,
          pinSpacing: false,
          onEnter: () => {
            if (headingRef.current) {
              (headingRef.current as HTMLElement).style.visibility = "visible";
            }
          }, // Make heading visible when pinned
          onUpdate: (self) => {
            setProgress(parseFloat(self.progress.toFixed(2)));
          },
          onLeaveBack: () => {
            if (headingRef.current) {
              (headingRef.current as HTMLElement).style.visibility = "hidden";
            }
          }, // Optionally hide when unpinned
        });
      },
      sectionRef,
    );
    return () => mm.revert();
  }, [headerAdditionalSpace]);

  useEffect(() => {
    const target = document.querySelector(
      "header#global-header div.dark.relative.z-40.transition-transform.duration-300.ease-out",
    );

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.target instanceof HTMLElement &&
          mutation.attributeName === "class" &&
          mutation.target.classList.contains("hidden")
        ) {
          setHeaderAdditionalSpace(0);
        } else {
          setHeaderAdditionalSpace(54);
        }
      });
    });
    if (target) {
      observer.observe(target, {
        attributes: true,
        attributeFilter: ["style", "class"], // Observe both style and class changes
      });
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return (
    <div ref={sectionRef} className="container relative mx-auto overflow-x-hidden">
      <div
        className="!left-0 z-20 hidden w-screen overflow-x-hidden bg-primary-dark-900 md:block"
        ref={headingRef}
        style={{ visibility: "hidden" }}
        id="jumplist-pin-spacer"
      >
        <div className="flex flex-row justify-evenly gap-4 border-b-2 border-primary-neutral-200/40">
          {json.tabs.map((tab, index) => {
            return (
              <div
                key={`tab-${index}`}
                onClick={() => {
                  jumpListScrollToIndex({ index, id: `node-${index}` });
                }}
                className={clsx(
                  typography.label,
                  "relative z-10 cursor-pointer rounded-md py-xs font-display text-sm font-bold text-primary-dark-100 transition-colors duration-300 hover:text-primary-light-500 xl:text-sm",
                  (progress * 100) / 25 >= index && "text-primary-light-500 delay-150",
                )}
              >
                {tab.title}
              </div>
            );
          })}
          <div
            className="absolute left-0 top-0 h-full border-b-2 border-primary-light-500 transition-transform duration-300 ease-in-out will-change-transform"
            style={{
              width: `calc(100% * ${progress})`,
            }}
          ></div>
        </div>
      </div>
      {json?.tabs.map((tab, index) => {
        const { refAssetId, refHeadingComponent, componentType } = tab;

        const asset = assets?.find((asset) => asset?.sys.id === refAssetId);
        const heading = componentHeading?.find((heading) => heading?.sys.id === refHeadingComponent);
        switch (componentType) {
          case "homepage-datasources":
            return (
              <div key={`node-${index}`}>
                <JumplistNode key={`node-${index}`} nodeID={`node-${index}`}>
                  <HomepageDatasource componentHeading={heading} assets={assets} />
                </JumplistNode>
              </div>
            );
          case "homepage-widgets":
            return (
              <div key={`node-${index}`}>
                <JumplistNode key={`node-${index}`} nodeID={`node-${index}`}>
                  <HomepageWidgets componentHeading={heading} assets={assets} />
                </JumplistNode>
              </div>
            );
          case "homepage-code":
            return (
              <div key={`node-${index}`}>
                <JumplistNode key={`node-${index}`} nodeID={`node-${index}`}>
                  <HomepageCode componentHeading={heading} assets={assets} />
                </JumplistNode>
              </div>
            );
          case "homepage-deploy":
            return (
              <div key={`node-${index}`}>
                <JumplistNode key={`node-${index}`} nodeID={`node-${index}`}>
                  <HomepageDeploy componentHeading={heading} assets={assets} />
                </JumplistNode>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
