import React from 'react';
import { IconProps } from './IconProps';

function CodeEditor_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'CodeEditor 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill} stroke={fill}>
        <path
          d="M1 2h22v20H1z"
          fill="none"
          stroke={fill}
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M7 2v20"
          fill="none"
          stroke={fill}
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M11 6h2"
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M15 10h4"
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M13 14h3"
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M11 18h2"
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
}

export default CodeEditor_24px;
