"use client";

import { cn } from "utils/functions";
import { sectionPaddingBottomClass, sectionPaddingTopClass } from "utils/sectionSpacingConstants";
import WhyLowCode from "./WhyLowCode";
import StackedShowcase from "./StackedShowcase";
import { AssetFragment } from "contentful/gql/graphql";
import DevDetailsCard from "./DevDetailsCard";
import ConversionPanelV3 from "components/ConversionPanel/ConversionPanelV3";

type LowCodeForDevsType = {
  componentType: string;
  type: string;
  content: any[] | undefined;
  assets: (AssetFragment | undefined)[] | undefined;
  cardType: string | undefined;
};

export default function LowCodeForDevs({
  id,
  json,
  assets,
}: {
  id: string;
  json: {
    componentTheme: string;
    paddingTop: string;
    paddingBottom: string;
    content: LowCodeForDevsType[] | undefined;
  };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <section
      id={id}
      className={cn(
        json.componentTheme?.toLowerCase() == "dark"
          ? "dark bg-primary-dark-900 text-primary-neutral-100"
          : "bg-primary-neutral-50 text-primary-dark-800",
      )}
    >
      <div
        className={cn(
          "container mx-auto",
          sectionPaddingTopClass[(json?.paddingTop as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          sectionPaddingBottomClass[(json?.paddingBottom as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          "flex flex-col gap-lg",
        )}
      >
        {json.content?.map((item, index) => {
          switch (item.componentType) {
            case "why-low-code":
              return (
                <WhyLowCode key={`LowCodeDevs${item.componentType}-${index}`} json={item.content} type={item.type} />
              );
            case "usecase-stacked":
              if (item.content?.length) {
                return (
                  <StackedShowcase
                    key={`LowCodeDevs${item.componentType}-${index}`}
                    json={item.content[0]}
                    assets={assets}
                  />
                );
              }
              return null;
            case "dev-details-card":
              return (
                <DevDetailsCard
                  key={`LowCodeDevs${item.componentType}-${index}`}
                  json={item.content}
                  cardType={item.cardType || "dark"}
                />
              );
            case "cta-card":
              const data: any = {};
              if (item.content?.[0]?.elementLink) {
                data["callToActionCollection"] = {
                  items: [item.content?.[0]?.elementLink],
                };
              }
              return (
                <div className="pb-lg">
                  <ConversionPanelV3
                    key={`LowCodeDevs${item.componentType}-${index}`}
                    variation={"dark"}
                    heading={item.content?.[0]?.elementTitle || ""}
                    headingType={"H3"}
                    {...data}
                    // accentImage={accentImage}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </section>
  );
}
