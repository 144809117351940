export default function GradiantCardOutline({
  backgroundGradientClass,
  cardMainBackgroundClass,
  cardSecondaryBackgroundClass,
  children,
}: {
  backgroundGradientClass: string;
  cardMainBackgroundClass: string;
  cardSecondaryBackgroundClass: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={backgroundGradientClass}
    >
      <div className={cardMainBackgroundClass}>
        <div
          className={cardSecondaryBackgroundClass}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
