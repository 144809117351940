'use client';

import IntegrationCard from 'components/Cards/IntegrationCard/IntegrationCard';
import Tabs from 'components/Tabs/Tabs';
import typography from 'components/typography';
import { TemplateIntegrationListingFragment } from 'contentful/gql/graphql';
import { useState } from 'react';
import RequestCard from './RequestCard';

const IntegrationListings = ({
  integrations: integrationsList,
}: {
  integrations: (TemplateIntegrationListingFragment | null)[];
}) => {
  const tabs = [{ name: 'All' }, { name: 'APIs' }, { name: 'Databases' }];
  const otherServices = integrationsList?.filter(integration => integration?.otherServices);
  const integrations = integrationsList?.filter(integration => !integration?.otherServices);
  let filteredData = [];
  const [selectedTab, setSelectedTab] = useState('All');

  const onTabChange = (tab: string) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  if (selectedTab !== 'All') {
    filteredData = integrations?.filter?.(integration => integration?.category === selectedTab) || [];
  } else {
    filteredData = integrations || [];
  }

  return (
    <div>
      <div className="py-sm text-right">
        <Tabs tabs={tabs} selectedTab={selectedTab} onTabChange={onTabChange} />
      </div>
      <div className="grid grid-cols-2 gap-sm py-lg sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {filteredData?.map(item => item && <IntegrationCard key={item?.sys.id} integration={item} />)}
        <RequestCard />
      </div>
      <div className="py-lg">
        <h2 className={typography.heading.sectionHeading}>Connect to Other Services</h2>
        <p className={typography.body.componentBody}>
          Appsmith lets you talk to many other SaaS/BaaS platforms through APIs.
        </p>
        <div className="grid grid-cols-2 gap-sm pt-lg sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {otherServices?.map(item => item && <IntegrationCard key={item?.sys.id} integration={item} />)}
        </div>
      </div>
    </div>
  );
};

export default IntegrationListings;
