"use client";

import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { useMemo } from "react";
import { adjustImageDimensions, cn } from "utils/functions";

export interface HomepageLeadingCompaniesInnovatingConfigType {
  items: Item[];
}

interface Item {
  heading: string;
  body: string;
  imageAssetReferenceId: string;
  companyLogoId: string;
  companyName: string;
  caseStudyLink: string;
}

export default function HomepageLeadingCompaniesInnovating(props: {
  json: HomepageLeadingCompaniesInnovatingConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
  components: (ComponentHeadingFragment | undefined)[] | undefined;
}) {
  const headingComponent = useMemo(
    () =>
      props.components?.map((component, index) => {
        if (component) {
          return <ComponentHeading key={`${component?.sys.id}-${index}`} {...component} />;
        }
        return null;
      }),
    [props.components],
  );
  return (
    <div className="relative">
      <div className="mb-sm md:mb-lg">{headingComponent}</div>

      <div className="relative mx-auto grid w-9/12 gap-6 rounded-lg transition-colors duration-300 md:grid-cols-4">
        {props.json.items.map((item, index) => {
          const { heading, body, companyName, imageAssetReferenceId, companyLogoId } = item;
          const image = props.assets?.find((asset) => asset?.sys.id === imageAssetReferenceId);
          const companyLogo = props.assets?.find((asset) => asset?.sys.id === companyLogoId);
          const { width: companyLogoWidth, height: companyLogoHeight } = adjustImageDimensions({
            baseWidth: 36,
            scaleFactor: 0.75,
            width: companyLogo?.width || 0,
            height: companyLogo?.height || 0,
          });
          return (
            <Hyperlink
              key={`${heading}-${index}-${companyName}`}
              href={item.caseStudyLink}
              className="relative-3 group relative z-10 col-span-2 flex-col rounded-lg border border-secondary-light-100/80 bg-secondary-light-100/80 p-sm transition-colors duration-300 ease-linear hover:border-primary-light-500 hover:bg-primary-neutral-50 hover:shadow-lg"
            >
              <div className="">
                <div className="max-md:md-2xs mb-xs">
                  <span
                    className={cn(
                      typography.body.xs,
                      "relative inline-block rounded-full bg-primary-light-100/40 px-xs py-4xs text-xs font-medium text-primary-light-500 md:text-sm",
                    )}
                  >
                    {heading}
                  </span>
                  <div className="mt-xs w-full border-b border-b-primary-dark-100 max-md:mt-2xs"></div>
                </div>
                <div className={"lg:h-[220px]"}>
                  {image && (
                    <Image
                      src={image.url ?? ""}
                      height={image.height ?? 0}
                      width={image.width ?? 0}
                      alt={image.title ?? "Feature image"}
                      className="h-full w-full rounded"
                    />
                  )}
                </div>
                <div className={"align-start my-xs flex origin-left object-contain max-md:my-2xs lg:h-[30px]"}>
                  {companyLogo && (
                    <Image
                      src={companyLogo.url ?? ""}
                      height={companyLogoHeight ?? 0}
                      width={companyLogoWidth ?? 0}
                      alt={companyLogo.title ?? "Feature image"}
                      className="origin-left object-contain brightness-0 grayscale max-md:scale-75"
                    />
                  )}
                </div>
                <div>
                  <p
                    className={cn(
                      typography.body.componentBody,
                      "text-primary-dark-8000 font-light dark:text-primary-neutral-50/70",
                    )}
                  >
                    <span className="font-bold">{`${companyName}`} </span>
                    {body}
                  </p>
                </div>
              </div>
            </Hyperlink>
          );
        })}
      </div>
    </div>
  );
}
