import { cn } from "./functions";

export const sectionPaddingTopClass = {
  Small: cn("pt-2xs md:pt-xs xl:pt-sm"),
  Medium: cn("pt-sm md:pt-sm xl:pt-md"),
  Large: cn("pt-sm md:pt-md xl:pt-lg"),
};

export const sectionPaddingBottomClass = {
  Small: cn("pb-2xs md:pb-xs xl:pb-sm"),
  Medium: cn("pb-xs md:pb-sm xl:pb-md"),
  Large: cn("pb-sm md:pb-md xl:pb-lg"),
};
