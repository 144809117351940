import clsx from 'clsx';
import Hyperlink from 'components/Hyperlink';
import typography from 'components/typography';
import { assetFragment } from 'contentful/fragments';
import { getFragmentData } from 'contentful/gql';
import { TemplateUseCaseListingFragment } from 'contentful/gql/graphql';
import Image from 'next/image';

const UseCaseCard = ({ useCase }: { useCase: TemplateUseCaseListingFragment }) => {
  const { title, slug, featuredImage: image } = useCase;
  const featuredImage = getFragmentData(assetFragment, image);
  return (
    <Hyperlink
      href={`/use-case/${slug}`}
      className="group flex flex-col overflow-hidden rounded-lg bg-secondary-light-100/60 dark:bg-primary-neutral-50"
    >
      {featuredImage?.url && (
        <Image
          className="aspect-[2.1] w-full object-cover object-top"
          src={featuredImage?.url}
          alt={featuredImage.title || 'Feature Image'}
          width={featuredImage?.width || 278}
          height={featuredImage?.height || 170}
        />
      )}
      <h3 className={clsx(typography.heading.cardHeading, 'p-sm group-hover:underline')}>{title}</h3>
    </Hyperlink>
  );
};

export default UseCaseCard;
