"use client";

import CardDeck from "components/CardDeck/CardDeck";
import WidgetIconCard from "components/Cards/WidgetCard/WidgetIconCard";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { AssetFragment, ComponentCardDeckFragment, TemplateWidgetListingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import type { FC } from "react";
import { cn } from "utils/functions";

interface WidgetsListingProps {
  widgets: (TemplateWidgetListingFragment | undefined)[] | undefined;
  assets: (AssetFragment | undefined)[] | undefined;
  json: {
    widgetNames: string[];
    featuredWidgetLargeImage: string;
    featuredWidgetSmallImage: string;
    featuredWidgetSmallText: { heading: string; body: string };
  };
  componentCardDeck: ComponentCardDeckFragment | undefined;
}

const DisplayWidgets: FC<WidgetsListingProps> = ({ widgets, assets, json, componentCardDeck }) => {
  const displayWidgets = widgets?.filter((widget) => widget?.title && json.widgetNames.includes(widget.title));
  const featuredWidgetLargeImage = assets?.find((asset) => asset?.title === json.featuredWidgetLargeImage);
  const featuredWidgetSmallImage = assets?.find((asset) => asset?.title === json.featuredWidgetSmallImage);
  const tableWidget = widgets?.find((widget) => widget?.title === "Table");
  const tableWidgetIcon = getFragmentData(assetFragment, tableWidget?.widgetIcon);
  const chartWidget = widgets?.find((widget) => widget?.title === "Chart");
  const chartWidgetIcon = getFragmentData(assetFragment, chartWidget?.widgetIcon);
  return (
    <div>
      <section className="space-y-sm rounded-2xl bg-secondary-light-50 p-sm md:space-y-lg">
        <div className="grid w-full auto-cols-fr grid-flow-col grid-rows-4 gap-xs md:grid-rows-1">
          {displayWidgets?.map((widget, index) => (widget ? <WidgetIconCard key={index} widget={widget} /> : null))}
        </div>
        <div className="relative pt-sm md:pt-0">
          {featuredWidgetLargeImage ? (
            <Image
              src={featuredWidgetLargeImage.url ?? ""}
              alt="Featured widget large image"
              height={featuredWidgetLargeImage.height ?? 0}
              width={featuredWidgetLargeImage.width ?? 0}
            />
          ) : null}
          {tableWidget ? (
            <Hyperlink
              href={tableWidget.externalLink ?? `https://docs.appsmith.com/reference/widgets/${tableWidget.slug}`}
              className="group absolute left-[2%] top-0 flex max-w-min items-center justify-center rounded-lg border border-primary-light-300 bg-primary-neutral-50 px-lg py-3xs md:left-[1%] md:top-[1%]"
            >
              {tableWidgetIcon ? (
                <Image
                  src={tableWidgetIcon?.url ?? ""}
                  alt="Table widget icon"
                  height={tableWidgetIcon?.height ?? 0}
                  width={tableWidgetIcon?.width ?? 0}
                  className="h-md w-md"
                />
              ) : null}
              <h3 className="ml-2xs font-display text-sm font-bold group-hover:underline xl:text-base">
                {tableWidget.title}
              </h3>
            </Hyperlink>
          ) : null}
        </div>
        <div>{componentCardDeck ? <CardDeck {...componentCardDeck} /> : null}</div>
        <div className="flex flex-col items-center md:flex-row">
          <div className="relative pt-sm md:w-2/3 md:pt-0">
            {featuredWidgetSmallImage ? (
              <Image
                src={featuredWidgetSmallImage.url ?? ""}
                alt="Featured widget small image"
                height={featuredWidgetSmallImage.height ?? 0}
                width={featuredWidgetSmallImage.width ?? 0}
              />
            ) : null}
            {chartWidget ? (
              <Hyperlink
                href={chartWidget.externalLink ?? `https://docs.appsmith.com/reference/widgets/${chartWidget.slug}`}
                className="group absolute left-[2%] top-0 flex max-w-min items-center justify-center rounded-lg border border-primary-light-300 bg-primary-neutral-50 px-lg py-3xs md:top-[2%]"
              >
                {chartWidgetIcon ? (
                  <Image
                    src={chartWidgetIcon?.url ?? ""}
                    alt="Chart widget icon"
                    height={chartWidgetIcon?.height ?? 0}
                    width={chartWidgetIcon?.width ?? 0}
                    className="h-sm w-sm"
                  />
                ) : null}
                <h3 className="ml-2xs font-display text-xs font-bold group-hover:underline xl:text-sm">
                  {chartWidget.title}
                </h3>
              </Hyperlink>
            ) : null}
          </div>
          <div className="md:w-1/3">
            <h3 className={cn(typography.heading.secondaryComponentHeading, "mb-xs text-center")}>
              {json.featuredWidgetSmallText.heading}
            </h3>
            <p className="text-center">{json.featuredWidgetSmallText.body}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DisplayWidgets;
