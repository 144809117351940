import { cn } from "utils/functions";
import typography from "components/typography";
import Icon from "components/Icon";
import ListItemIconText from "components/ListItem/ListItemIconText";
import GradiantCardOutline from "components/Cards/GradiantCard/GradiantCardOutline";

type LowCodeDevOpsPointersType = {
  elementPosition: string;
  elementType: string;
  elementTitle?: string;
  content: {
    icon: string;
    label: string;
  }[];
};

export default function LowCodeDevOpsPointers({
  json,
  cardType,
}: {
  json: LowCodeDevOpsPointersType[];
  cardType: string;
}) {
  return (
    <GradiantCardOutline
      backgroundGradientClass={cn(
        "p-[1px]",
        cardType === "dark"
          ? "rounded-lg bg-gradient-to-b from-primary-dark-500 to-primary-dark-500/0"
          : "rounded-lg bg-gradient-to-b from-tertiary-pink-500 to-tertiary-pink-500/0",
      )}
      cardMainBackgroundClass={cn(cardType === "dark" ? "bg-primary-dark-900" : "bg-tertiary-pink-500", "rounded-lg")}
      cardSecondaryBackgroundClass={cn(
        "rounded-lg p-md lg:p-lg",
        cardType === "dark" ? "bg-primary-neutral-50/[0.04]" : "bg-primary-neutral-50/[0.9]",
      )}
    >
      {json.map((item, index) => {
        return (
          <div className={cn("flex flex-col gap-xs")} key={`LowCodeDevOpsPointers-${index}`}>
            {item?.elementTitle && (
              <div>
                <h3 className={cn(typography.heading.cardHeading)}>{item?.elementTitle}</h3>
              </div>
            )}
            <div className="grid grid-cols-1 gap-xs md:grid-cols-2">
              {item.elementType === "pointers-list" && item.content.map((content, ind) => {
                return (
                  <ListItemIconText
                    key={`LowCodeDevOpsPointers-${ind}`}
                    clName="relative flex gap-3xs"
                    text={content.label}
                    icon={content.icon}
                    textClass={null}
                    iconClass={null}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </GradiantCardOutline>
  );
}
