import clsx from "clsx";
import typography from "components/typography";
import { cn } from "utils/functions";
import GradiantCardOutline from "./GradiantCardOutline";

export default function GradiantCard({
  heading,
  headingType,
  description,
  reference,
  cardType,
  descriptionType,
  referenceType,
}: {
  heading: string;
  headingType: string;
  description: string;
  cardType: string;
  descriptionType: string;
  referenceType: string;
  reference: string;
}) {
  return (
    <GradiantCardOutline
      backgroundGradientClass={cn(
        "p-[1px] rounded-lg",
        cardType === "dark"
          ? "bg-gradient-to-b from-primary-dark-500 to-primary-dark-500/0"
          : "bg-gradient-to-b from-tertiary-pink-500 to-tertiary-pink-500/0",
      )}
      cardMainBackgroundClass={cn(cardType === "dark" ? "bg-primary-dark-900" : "bg-tertiary-pink-500", "rounded-lg")}
      cardSecondaryBackgroundClass={cn(
        "rounded-lg p-xs lg:p-sm",
        cardType === "dark" ? "bg-primary-neutral-50/[0.04]" : "bg-primary-neutral-50/[0.95]",
      )}
    >
      <>
        {heading && headingType === "H2" ? (
          <h2 className={clsx(typography.heading.componentHeading, "mb-3xs text-tertiary-pink-500")}>{heading}</h2>
        ) : headingType === "H3" ? (
          <h3 className={clsx(typography.heading.secondaryComponentHeading, "mb-3xs text-tertiary-pink-500")}>
            {heading}
          </h3>
        ) : headingType === "H4" ? (
          <h4 className={clsx(typography.heading.cardHeading, "mb-3xs text-tertiary-pink-500")}>{heading}</h4>
        ) : (
          <h3 className={clsx(typography.heading.cardHeading, "mb-3xs text-tertiary-pink-500")}>{heading}</h3>
        )}
        {description && (
          <dd
            className={cn(
              descriptionType === "low-light" ? "text-primary-neutral-200/80" : "text-secondary-dark-700",
              "mb-3xs text-xs",
            )}
          >
            {description}
          </dd>
        )}
        {reference && (
          <dd
            className={cn(
              referenceType === "high-light" ? "text-primary-neutral-50" : "text-primary-dark-900/60",
              "font-display font-medium text-xs",
            )}
          >
            {reference}
          </dd>
        )}
      </>
    </GradiantCardOutline>
  );
}
