'use client';

import { FC, useEffect, useState } from 'react';
import TemplateCard from 'components/Cards/TemplateCard/TemplateCard';
import { FiPlus } from 'react-icons/fi';
import TemplateFilterMobile from './TemplateFilterMobile';
import { Maybe, TemplateTemplateListingFragment } from 'contentful/gql/graphql';
import typography from 'components/typography';

interface TemplateListingProps {
  templates?: (TemplateTemplateListingFragment | null)[];
}

const TemplateListing: FC<TemplateListingProps> = ({ templates }) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<(TemplateTemplateListingFragment | null)[]>([]);
  const [filter, setFilter] = useState<string[]>([]);

  const handleFilter = (value: string) => {
    if (!filter.includes(value)) {
      setFilter([...filter, value]);
    } else {
      const modified = filter.filter(fl => fl !== value);
      setFilter(modified);
    }
  };

  const cats = templates?.map(item => item?.category).sort();
  const categories = [...new Set(cats)];

  useEffect(() => {
    const modifiedData: (TemplateTemplateListingFragment | null)[] =
      templates?.filter(item => item?.category && filter.includes(item?.category)) || [];
    filter.length ? setFilteredData(modifiedData) : setFilteredData(templates || []);
  }, [filter, templates]);

  const renderCards = (
    <div className="grid grid-cols-1 justify-center gap-sm lg:grid-cols-3">
      {filteredData?.map(template => template && <TemplateCard key={template?.sys.id} template={template} />)}
    </div>
  );

  return (
    <main>
      <TemplateFilterMobile
        isOpen={mobileFiltersOpen}
        setOpen={setMobileFiltersOpen}
        categories={categories}
        filters={filter}
        handleFilter={handleFilter}
      />
      <div className="grid gap-sm lg:grid-cols-12">
        <aside className="col-span-3 xl:col-span-2">
          <div className="sticky top-3xl">
            <h2 className="sr-only">Filters</h2>
            <button
              type="button"
              className="mb-sm inline-flex items-center lg:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <span className="text-sm font-medium ">Filters</span>
              <FiPlus className="ml-2xs h-2xs w-2xs flex-shrink-0 " aria-hidden="true" />
            </button>

            <div className="hidden lg:block">
              <form className="divide-gray-200 space-y-2xs divide-y">
                <fieldset>
                  <legend className="block text-base font-medium">Category</legend>
                  <div className="pt-2xs">
                    {categories?.map(
                      option =>
                        option && (
                          <div key={option} className="flex cursor-pointer items-center">
                            <input
                              id={`cat-${option}`}
                              checked={filter.includes(option)}
                              onChange={() => handleFilter(option)}
                              type="checkbox"
                              className="rounded"
                            />
                            <label htmlFor={`cat-${option}`} className="ml-2xs text-base">
                              {option}
                            </label>
                          </div>
                        ),
                    )}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </aside>
        <div className="col-span-12 lg:col-span-9 xl:col-span-10">{renderCards}</div>
      </div>
    </main>
  );
};

export default TemplateListing;
