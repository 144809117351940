import Icon from "components/Icon";
import typography from "components/typography";
import { cn } from "utils/functions";
import "./LowCodeBuyerGuideEvaluation.css";

type LowCodeBuyerGuideEvaluationProps = {
  title: string;
  description: string;
  icon: string;
};

export default function LowCodeBuyerGuideEvaluation({ json }: { json: LowCodeBuyerGuideEvaluationProps[] }) {
  return (
    <div className="grid grid-cols-1 gap-sm md:grid-cols-2">
      {json.map((item, index) => {
        return (
          <div key={index} className="col-span-1 rounded-lg bg-primary-neutral-50/20 flex gap-xs rounded-lg p-sm card-background-gradient">
            {item.icon && (
                <div className="rounded-lg bg-primary-neutral-50/10 p-xs h-lg w-lg flex items-center justify-center">
                  <div className="relative h-xs min-h-xs w-xs min-w-xs text-primary-neutral-50">
                    <Icon url={`/assets/icons/${item.icon}.svg`} />
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-2xs">
                <div className={cn(typography.heading.cardHeading, "text-tertiary-pink-500")}>{item.title}</div>
                <div className={cn(typography.body.cardBody, "font-light")}>{item.description}</div>
              </div>
          </div>
        );
      })}
    </div>
  );
}
