'use client';

import Image from 'next/image';
import React, { useCallback, useEffect, useReducer, useRef, useState, useLayoutEffect } from 'react';
import { ComponentLogoCloudFragment } from 'contentful/gql/graphql';
import { getFragmentData } from 'contentful/gql';
import { assetFragment, entityCompanyFragment } from 'contentful/fragments';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clsx from 'clsx';
import typography from 'components/typography';
import { cn } from 'utils/functions';

const adjustImageDimensions = function (width: number, height: number) {
  const baseWidth = 42;
  const scaleFactor = 0.55;
  const imageRatio = width / height;
  const newWidth = Math.ceil(Math.pow(imageRatio, scaleFactor) * baseWidth);
  const newHeight = Math.ceil(newWidth / imageRatio);

  return { width: newWidth, height: newHeight };
};

const EmbeddedLogoCloud = ({ heading, headingType, companyCollection }: ComponentLogoCloudFragment) => {
  let logos =
    companyCollection?.items?.map(item => {
      const company = getFragmentData(entityCompanyFragment, item);
      const logo = getFragmentData(assetFragment, company?.logoOnLight);
      const { width, height } = adjustImageDimensions(logo?.width || 0, logo?.height || 0);
      return { company, logo, width, height };
    }) || [];

  return (
    <div className="mt-md">
      <h2
        className={cn(
          typography.heading.cardHeading,
          'mb-2xs text-left font-normal text-primary-dark-800/60 dark:text-primary-neutral-50/80',
        )}
      >
        {heading}
      </h2>
      <div className="flex flex-wrap items-center">
        {logos?.map(({ company, logo, width, height }, index) => {
          return (
            <div
              className="my-sm mr-md flex items-center justify-center"
              key={company?.sys.id + '-' + index}
              style={{ width: `${width}px`, height: `${height}px` }}
            >
              <Image
                className="object-contain object-center contrast-200 grayscale"
                style={{ width: `${width}px`, height: `${height}px` }}
                src={logo?.url || ''}
                alt={company?.companyName || 'Company Logo Cloud'}
                height={height}
                width={width}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmbeddedLogoCloud;
