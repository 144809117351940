import typography from "components/typography";
import { cn } from "utils/functions";
import ListItemIconText from "components/ListItem/ListItemIconText";

type LowCodeMythTruthLikeProps = {
    label: string;
    icon: string;
};
export default function LowCodeMythTruthLike({
  json,
}: {
  json: {
    elementTitle: string;
    content: LowCodeMythTruthLikeProps[];
  }[];
}) {
  return (
    <div className="flex flex-col gap-sm md:flex-row">
      {json.map((item, index) => {
        return (
          <div
            className={cn("relative rounded-lg border-2 border-tertiary-pink-50 p-sm flex-1")}
            key={`LowCodeMythTruthLike-${index}`}
          >
            <div className="mb-xs">
                <h4 className={cn(typography.heading.secondaryComponentHeading, "text-tertiary-pink-500")}>{item.elementTitle}</h4>
            </div>
            <div className={cn("flex flex-col gap-xs")}>
              {item.content.map((data, ind) => {
                return (
                  <div className="flex gap-sm" key={`LowCodeMythTruthLike-${index}-${ind}`}>
                    <ListItemIconText
                      icon={data.icon}
                      text={data.label}
                      clName="flex gap-2xs"
                      textClass={cn("text-primary-dark-900", typography.body.sm)}
                      iconClass="text-tertiary-pink-500"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
