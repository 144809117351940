import LinkHeading from "components/ComponentHeading/LinkHeading";
import typography from "components/typography";
import { cn } from "utils/functions";

type LowCodeMythTruthHeadingProps = {
  elementType: string;
  elementTitle: string;
  elementSubHeading: string;
  elementLink: string;
};

export default function LowCodeMythTruthHeading({ json }: { json: LowCodeMythTruthHeadingProps }) {
  return (
    <div className="flex flex-col gap-xs">
      <LinkHeading
        headingType={"H2"}
        heading={json.elementTitle ?? ""}
        href={json.elementLink ?? ""}
        headingAdditionalClass="group-hover:underline group-hover:text-tertiary-pink-500 inline-block"
        iconName="arrow"
        iconColorClass="text-primary-dark-900"
        textAlign="text-left"
      />
      <div>
        {json.elementSubHeading && (
          <h3 className={cn(typography.heading.innerSubheading, "text-tertiary-pink-500")}>{json.elementSubHeading}</h3>
        )}
      </div>
    </div>
  );
}
