import Hyperlink from 'components/Hyperlink';
import typography from 'components/typography';

const RequestCard = () => (
  <div className="col-span-2">
    <div className="p-sm text-center">
      <h3 className={typography.heading.cardHeading}>Use a datasource we don&rsquo;t have yet?</h3>
      <p className={typography.body.cardBody}>Request a new integration and we&rsquo;ll get cracking ASAP!</p>
      <Hyperlink
        target="_blank"
        rel="noopener noreferrer"
        className="mt-sm block underline"
        href="https://github.com/appsmithorg/appsmith/issues/new?assignees=Nikhil-Nandagopal&labels=Enhancement&template=----feature-request.md&title=%5BFeature%5D"
      >
        Open an issue on GitHub
      </Hyperlink>
    </div>
  </div>
);

export default RequestCard;
