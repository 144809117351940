"use client";

import { cn } from "utils/functions";
import { sectionPaddingBottomClass, sectionPaddingTopClass } from "utils/sectionSpacingConstants";
import { AssetFragment } from "contentful/gql/graphql";
import LowCodeWhatWorksWhenHeading from "./LowCodeWhatWorksWhenHeading";
import LowCodeWhatWorksWhenTable from "./LowCodeWhatWorksWhenTable";
import ConversionPanelV2 from "components/ConversionPanel/ConversionPanelV2";

type LowCodeWhatWorksWhenType = {
  componentType: string;
  content: any;
};

export default function LowCodeWhatWorksWhen({
  id,
  json,
  assets,
}: {
  id: string;
  json: {
    componentTheme: string;
    paddingTop: string;
    paddingBottom: string;
    content: LowCodeWhatWorksWhenType[] | undefined;
  };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <section
      id={id}
      className={cn(
        json.componentTheme?.toLowerCase() == "dark"
          ? "dark bg-primary-dark-900 text-primary-neutral-100"
          : "bg-primary-neutral-50 text-primary-dark-800",
      )}
    >
      <div
        className={cn(
          "container mx-auto",
          sectionPaddingTopClass[(json?.paddingTop as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          sectionPaddingBottomClass[(json?.paddingBottom as keyof typeof sectionPaddingTopClass) ?? "Medium"],
          "flex flex-col gap-sm",
        )}
      >
        {json.content?.map((item, index) => {
          switch (item.componentType) {
            case "heading":
              return <LowCodeWhatWorksWhenHeading json={item.content} />;
            case "low-code-what-works-when-table":
              return <LowCodeWhatWorksWhenTable json={item.content} />;
            case "cta":
              const data: any = {};
              const image = assets?.find((asset) => asset?.sys?.id === item.content?.[0]?.elementImage);
              if (item.content?.[0]?.elementLink) {
                data["callToActionCollection"] = {
                  items: [item.content?.[0]?.elementLink],
                };
              }
              if (image) {
                data["accentImage"] =  image;
              }
              return (
                <div className="pb-lg">
                  <ConversionPanelV2
                    key={`LowCodeDevs${item.componentType}-${index}`}
                    variation={"dark"}
                    heading={item.content?.[0]?.elementTitle || ""}
                    headingType={"H3"}
                    {...data}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </section>
  );
}
