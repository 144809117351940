'use client';

import { FC, useEffect, useRef } from "react";

const JobBoard: FC = () => {
  const jobBoarddiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (jobBoarddiv.current) {
      const script = document.createElement('script');
      script.src = "https://wellfound.com/javascripts/embed_jobs.js";
      script.id = 'angellist_embed';
      script.async = true;
      script.setAttribute('data-startup', 'appsmith');
      jobBoarddiv.current.appendChild(script);
    }
  }, []);

  return <div ref={jobBoarddiv} />;
};

export default JobBoard;
