import { analytics } from "lib/segment";
import { useReducer } from "react";

export interface PricingCalculatorState {
  frequentUsers: number;
  infrequentUsers: number;
  averageUserHours: number;
}

export interface PricingCalculatorActions {
  type: "frequentUsersChanged" | "infrequentUsersChanged" | "averageUserHoursChanged";
  payload: number;
}

const initialState: PricingCalculatorState = {
  frequentUsers: 10,
  infrequentUsers: 10,
  averageUserHours: 25,
};

const pricingCalculatorReducer = (state: PricingCalculatorState, action: PricingCalculatorActions) => {
  analytics?.track("Used Pricing Calculator", {
    action: action.type,
    value: action.payload,
  });

  switch (action.type) {
    case "frequentUsersChanged": {
      const frequentUsers = action.payload > 999999 ? state.frequentUsers : action.payload;

      return {
        ...state,
        frequentUsers,
      };
    }
    case "infrequentUsersChanged": {
      const infrequentUsers = action.payload > 999999 ? state.infrequentUsers : action.payload;

      return {
        ...state,
        infrequentUsers,
      };
    }
    case "averageUserHoursChanged": {
      const averageUserHours = action.payload > 24 * 30 ? 24 * 30 : action.payload;
      return {
        ...state,
        averageUserHours,
      };
    }
  }
};

export const usePricingCalculatorReducer = () => {
  const [state, dispatch] = useReducer(pricingCalculatorReducer, initialState);

  return { state, dispatch };
};
