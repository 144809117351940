import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateIntegrationListingFragment } from "contentful/gql/graphql";
import Image from "next/image";

const IntegrationCard = ({ integration }: { integration: TemplateIntegrationListingFragment }) => {
  const { externalLink, slug, integrationImage, title } = integration;
  const image = getFragmentData(assetFragment, integrationImage);
  return (
    <Hyperlink
      href={externalLink ?? `/integration/${slug}`}
      className="group flex flex-col items-center justify-center rounded-lg bg-secondary-light-100/60 p-sm text-center dark:bg-primary-neutral-50"
    >
      {image?.url && (
        <Image
          className="mb-sm h-3xl w-3xl object-contain p-sm"
          width={image?.width || 256}
          height={image?.height || 256}
          src={image?.url || ""}
          alt={image?.title || "Integration image"}
        />
      )}
      <h3 className={clsx(typography.label, "group-hover:underline")}>{title}</h3>
    </Hyperlink>
  );
};

export default IntegrationCard;
