import GradiantCardOutline from "components/Cards/GradiantCard/GradiantCardOutline";
import Icon from "components/Icon";
import ListItemIconText from "components/ListItem/ListItemIconText";
import typography from "components/typography";
import { cn } from "utils/functions";

type ElementContent = {
  icon: string;
  label: string;
};

type ElementJson = {
  elementType: string;
  elementTitle: string | undefined;
  elementPosition: string;
  elementIcon: string;
  elementContent: ElementContent[] | undefined;
};

export default function DevDetailsCard({ json, cardType }: { json: ElementJson[] | any; cardType: string }) {
  return (
    <GradiantCardOutline
      backgroundGradientClass={cn(
        "p-[1px]",
        cardType === "dark"
          ? "rounded-lg bg-gradient-to-b from-primary-dark-500 to-primary-dark-500/0"
          : "rounded-lg bg-gradient-to-b from-tertiary-pink-500 to-tertiary-pink-500/0",
      )}
      cardMainBackgroundClass={cn(cardType === "dark" ? "bg-primary-dark-900" : "bg-tertiary-pink-500", "rounded-lg")}
      cardSecondaryBackgroundClass={cn(
        "rounded-lg p-md lg:p-lg",
        cardType === "dark" ? "bg-primary-neutral-50/[0.04]" : "bg-primary-neutral-50/[0.9]",
      )}
    >
      <div className="flex flex-col gap-md">
        {json.map((item: ElementJson, index: number) => {
          if (item.elementType === "title") {
            return (
              <div key={`title-${index}`}>
                <h3 className={cn(typography.heading.cardHeading, "font-normal")}>{item.elementTitle}</h3>
              </div>
            );
          } else if (item.elementType === "horizontal-pointers") {
            return (
              <div
                className="grid grid-cols-1 gap-md md:grid-cols-3 md:gap-lg lg:gap-2xl"
                key={`horizontal-pointers-${index}`}
              >
                {item.elementContent?.map((item: ElementContent, ind: number) => {
                  return (
                    <ListItemIconText
                      key={`horizontal-pointers-item-${ind}`}
                      clName="relative flex gap-3xs"
                      icon={item.icon}
                      text={item.label}
                      textClass={null}
                      iconClass={null}
                    />
                  );
                })}
              </div>
            );
          }
          return null;
        })}
      </div>
    </GradiantCardOutline>
  );
}
