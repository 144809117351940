import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { EntityCompanyFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { cn } from "utils/functions";

const adjustImageDimensions = function (width: number, height: number) {
  const baseWidth = 140;
  const scaleFactor = 0.8;
  const imageRatio = width / height;
  const newWidth = Math.ceil(Math.pow(imageRatio, scaleFactor) * baseWidth);
  const newHeight = Math.ceil(newWidth / imageRatio);

  return { width: newWidth, height: newHeight };
};

const PartnerCard = ({ partner }: { partner?: EntityCompanyFragment }) => {
  const logo = getFragmentData(assetFragment, partner?.logoIcon);
  const { width, height } = adjustImageDimensions(logo?.width ?? 120, logo?.height ?? 60);
  return partner ? (
    <Hyperlink
      href={partner.website ?? "#"}
      target="_blank"
      className="group flex flex-col overflow-hidden rounded-lg bg-secondary-light-100/60 dark:bg-primary-neutral-50"
    >
      {logo && (
        <Image
          alt={partner.companyName ?? "Partner logo"}
          className="mx-auto aspect-[2.1] w-3/4 object-contain p-sm"
          src={logo?.url ?? ""}
          height={width}
          width={height}
        />
      )}
      <h3 className={cn(typography.heading.cardHeading, "mb-3xs p-sm pb-0 group-hover:underline")}>
        {partner.companyName}
      </h3>
      <p className={cn(typography.body.cardBody, "p-sm pt-0")}>{partner.shortDescription}</p>
    </Hyperlink>
  ) : null;
};

export default PartnerCard;
