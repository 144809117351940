import { useRouter } from 'next/navigation';
import { FC } from 'react';

interface TabsProps {
  tabs: {
    name: string;
    href?: string;
  }[];
  selectedTab: string;
  onTabChange: (value: string) => void;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const Tabs: FC<TabsProps> = ({ tabs, selectedTab, onTabChange }) => {
  const router = useRouter();
  return (
    <div>
      <div className="bg-secondary-light-100/60 pr-2xs sm:hidden ">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md bg-[transparent] px-2xs py-xs text-base"
          defaultValue={selectedTab}
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex justify-end space-x-sm pb-sm" aria-label="Tabs">
          {tabs.map(tab => (
            <button
              key={tab.name}
              onClick={() => (tab.href ? router.push(tab.href) : onTabChange(tab.name))}
              className={classNames(
                tab.name === selectedTab ? 'bg-secondary-light-100' : ' hover:bg-secondary-light-100/50',
                'rounded px-sm py-2xs text-base font-medium',
              )}
              aria-current={tab.name === selectedTab ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
