'use client';

import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import CtaLink from "components/CtaLink";
import typography from "components/typography";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Meeting_32px from "icons/Meeting_32px";
import NewConstruction_32px from "icons/NewConstruction_32px";
import TrendUp_32px from "icons/TrendUp_32px";
import RenderMarkdown from "molecules/RenderMarkdown/RenderMarkdown";
import { useLayoutEffect, useRef } from "react";
import { cn } from "utils/functions";

export interface HomepagePricingCardsConfigType {
  pricingCards: PricingCard[];
}

interface PricingCard {
  edition: string;
  heading: string;
  subheading: string;
  body: string;
  ctaLink: CtaLink[];
}

interface CtaLink {
  label: string;
  href: string;
}

const container = cva(["flex flex-col justify-between rounded-xl translate-y-1/4 px-6 py-8 w-full"], {
  variants: {
    edition: {
      free: "bg-primary-dark-50",
      business: "bg-primary-dark-50",
      enterprise: "bg-primary-neutral-50",
    },
  },
});

export default function HomepagePricingCards({ json }: { json: HomepagePricingCardsConfigType }) {
  const sectionRef = useRef<any>(null);
  const tl = useRef<any>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          paused: true,
        })
        .to("[data-gsap-element='pricing-card']", {
          y: 0,
          opacity: 1,
          duration: 0.75,
          stagger: 0.1,
        });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 90%",
        onEnter: () => tl.current?.play(),
      });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 100%",
        onLeaveBack: () => tl.current?.pause(0),
      });
    });
  }, []);

  return (
    <div ref={sectionRef}>
      <div className="grid gap-sm md:grid-cols-3">
        {json.pricingCards.map((pricingCard, index) => {
          const { edition, body, ctaLink, heading, subheading } = pricingCard;

          return (
            <div
              key={index}
              data-gsap-element="pricing-card"
              style={
                edition === "enterprise"
                  ? {
                      boxShadow:
                        "0px 0px 0px 2px #7441F7, 0px 0px 0px 5px #FFFFFF, 0px 0px 0px 6px #7441F730, 0px 0px 0px 10px #FFFFFF, 0px 0px 0px 11px #7441F710",
                    }
                  : undefined
              }
              className={clsx(container({ edition: edition as VariantProps<typeof container>["edition"] }))}
            >
              <div className="mb-sm">
                <h3
                  className={cn("w-full pb-4xs text-left text-xl font-bold lg:text-3xl", {
                    "text-secondary-brand-500": edition === "business",
                    "bg-gradient-to-br from-primary-light-500 to-primary-light-300": edition === "enterprise",
                  })}
                  style={
                    edition === "enterprise"
                      ? {
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          color: "transparent",
                        }
                      : undefined
                  }
                >
                  {heading}
                </h3>
                <div className={typography.label}>{subheading}</div>
                <div className={clsx(typography.body.cardBody, "whitespace-normal text-primary-dark-800/70")}>
                  <RenderMarkdown markdown={body} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-sm md:grid-cols-2">
                {ctaLink.length > 0
                  ? ctaLink.map((cta, idx) => (
                      <CtaLink
                        key={edition + idx}
                        label={cta.label}
                        link={cta.href}
                        size={"Medium"}
                        variant={idx === 1 ? edition + "_secondary" : edition}
                        textAlign={"center"}
                        extendClassNames={cn(
                          "w-full",
                          ctaLink.length > 1 ? "md:col-span-2 lg:col-span-1" : "col-span-2",
                        )}
                      />
                    ))
                  : null}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-md flex justify-center">
        <CtaLink label="Compare plans" variant="Link" endIcon="Arrow" size="Medium" link="/pricing#plan-comparison" />
      </div>
    </div>
  );
}
