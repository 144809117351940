import LinkHeading from "components/ComponentHeading/LinkHeading";
import typography from "components/typography";
import { cn } from "utils/functions";

type LowCodeWhatWorksWhenHeadingProps = {
  elementType: string;
  elementTitle: string;
  elementBody: string[];
  elementLink: string;
};

export default function LowCodeWhatWorksWhenHeading({ json }: { json: LowCodeWhatWorksWhenHeadingProps }) {
  return (
    <div className="flex flex-col gap-sm">
      <LinkHeading
        headingType={"H2"}
        heading={json.elementTitle ?? ""}
        href={json.elementLink ?? ""}
        headingAdditionalClass="group-hover:underline group-hover:text-tertiary-pink-500 inline-block"
        iconName="arrow"
        iconColorClass="text-primary-neutral-50"
        textAlign="text-left"
      />
      <div>
        {json.elementBody.map((item, index) => {
          return (
            <div className={cn(typography.body.cardBody, "mb-sm")} key={index}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
}
