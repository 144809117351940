import GradiantCard from "components/Cards/GradiantCard/GradiantCard";
import ListItemIconText from "components/ListItem/ListItemIconText";
import typography from "components/typography";
import { cn } from "utils/functions";

type LowCodeCommercialSaaSPointsCardContent = {
  elementType: string;
  title: string;
  pointsContent:
    | {
        icon: string;
        label: string;
      }[]
    | undefined;
  cardsContent:
    | {
        type: string;
        cardType: string;
        heading: string;
        headingType: string;
        description: string;
        reference: string;
        descriptionType: string;
        referenceType: string;
      }[]
    | undefined;
};

export default function LowCodeCommercialSaaSPointsCard({ json }: { json: LowCodeCommercialSaaSPointsCardContent[] }) {
  return (
    <div className={cn(typography.body.cardBody, "mb-sm")}>
      <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-sm">
        {json.map((item, index) => {
          if (item.elementType === "cards") {
            return (
              <div className="max-lg:py-xs col-span-2" key={index}>
                <div className="flex flex-col gap-sm lg:gap-md">
                  {item.cardsContent?.map((card: any, ind: number) => {
                    return <GradiantCard {...card} key={ind} />;
                  })}
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="col-span-3">
              <h3 className={cn(typography.heading.innerSubheading, "mb-sm text-tertiary-pink-500")}>{item.title}</h3>
              <div className="flex flex-col gap-xs">
                {item.pointsContent?.map((point: any, ind: number) => {
                  return (
                    <ListItemIconText
                      key={`point-${ind}`}
                      clName="relative flex gap-3xs"
                      icon={point.icon}
                      text={point.label}
                      textClass={cn(typography.body.lg, "font-light")}
                      iconClass="relative top-[4px]"
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
