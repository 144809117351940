import React from 'react';
import { IconProps } from './IconProps';

function JsConsole_24px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'JsConsole 24px';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokewidth}
      >
        <rect height="20" width="20" fill="none" stroke={fill} x="2" y="2" />
        <polyline fill="none" points="7 8 11 12 7 16" />
        <line fill="none" x1="13" x2="17" y1="8" y2="8" />
        <line fill="none" x1="13" x2="17" y1="16" y2="16" />
        <line fill="none" x1="15" x2="17" y1="12" y2="12" />
      </g>
    </svg>
  );
}

export default JsConsole_24px;
