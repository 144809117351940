"use client";

import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import { AssetFragment, ComponentHeadingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { useMemo } from "react";

export interface G2RatingsConfigType {
  g2Badge: Item;
  stars: Item[];
}

interface Item {
  imageAssetReferenceId: string;
  details: string;
}

export default function G2Ratings(props: {
  json: G2RatingsConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
  components: (ComponentHeadingFragment | undefined)[] | undefined;
}) {
  const headingComponent = useMemo(
    () =>
      props.components?.map((component, index) => {
        if (component) {
          return <ComponentHeading key={`${component?.sys.id}-${index}`} {...component} />;
        }
        return null;
      }),
    [props.components],
  );

  const { g2Badge, stars } = props.json;
  const badge = props.assets?.find((asset) => asset?.sys.id === g2Badge.imageAssetReferenceId);

  return (
    <div className="relative">
      <div className="mx-auto mb-sm max-w-5xl">{headingComponent}</div>

      <div className="relative mx-auto">
        <div className="mx-auto mb-md w-full max-w-xl">
          {badge && (
            <Image
              src={badge.url ?? ""}
              height={badge.height ?? 0}
              width={badge.width ?? 0}
              alt={badge.title ?? "Feature image"}
              className="h-full w-full rounded"
              loading="lazy"
            />
          )}
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-center gap-4 sm:gap-8">
            {stars.map((star, index) => {
              const starAsset = props.assets?.find((asset) => asset?.sys.id === star.imageAssetReferenceId);
              return (
                <div key={index} className="flex flex-col items-center justify-between gap-3 md:flex-row">
                  <div className="max-w-[150px]">
                    {starAsset && (
                      <Image
                        src={starAsset.url ?? ""}
                        height={starAsset.height ?? 0}
                        width={starAsset.width ?? 0}
                        alt={starAsset.title ?? "Feature image"}
                        className="h-full w-full rounded"
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="text-md">{star.details}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
