import React, { useState, useEffect, useRef } from "react";

interface ProgressBarProps {
  duration: number; // Duration in seconds
  styleClass: string;
  onComplete: () => void | undefined;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ duration, styleClass, onComplete }) => {
  const [progress, setProgress] = useState(0); // Start with 0%
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const start = Date.now();

    const updateProgress = () => {
      const elapsedTime = Date.now() - start;
      const newProgress = (elapsedTime / duration) * 100; // Calculate current percentage
      setProgress(newProgress);

      if (elapsedTime >= duration) {
        clearInterval(intervalRef.current!);
        if (onComplete) {
          onComplete();
        }
      }
    };

    intervalRef.current = setInterval(updateProgress, 10);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [duration, onComplete]);

  return (
    <div
      className={styleClass}
      style={{
        width: "100%",
        transform: `scaleX(${progress / 100})`,
        transformOrigin: "left",
        transition: "transform 0.05s ease",
      }}
    />
  );
};

export default ProgressBar;
