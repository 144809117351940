import React from 'react';
import {IconProps} from "./IconProps"

function UserGlyph_32px(props: IconProps) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "UserGlyph 32px";

	return (
    <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill}>
        <circle cx="16" cy="8" r="7" />
        <path
          d="M16 17A13.452 13.452 0 0 0 2.526 29.96a1.002 1.002 0 0 0 .999 1.039h24.95a1.002 1.002 0 0 0 .999-1.039A13.45 13.45 0 0 0 16 17z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default UserGlyph_32px;