"use client";

import { MouseInfoProvider } from "@faceless-ui/mouse-info";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Block_24px from "icons/Block_24px";
import Image from "next/image";
import { useLayoutEffect, useRef } from "react";
import { cn } from "utils/functions";

export interface BusinessFeaturesGridConfigType {
  items: Item[];
}

interface Item {
  width?: string;
  icon?: string;
  heading?: string;
  body?: string;
  image?: string;
  href?: string;
}

const FeatureCard = ({
  item,
  image,
  bgImage,
  index,
}: {
  item: Item;
  image?: AssetFragment;
  bgImage?: AssetFragment;
  index: number;
}) => {
  const { width, icon, heading, body, href } = item;
  const cardRef = useRef<any>(null);
  const tl = useRef<any>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: cardRef.current,
            start: "top 120%",
          },
        })
        .to(cardRef.current, {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 0.75,
          delay: index !== 0 && index % 2 === 1 ? 0 : 0.1,
        });
      ScrollTrigger.create({
        trigger: cardRef.current,
        start: "top 120%",
        onLeaveBack: () => tl.current?.pause(0),
      });
    }, cardRef.current);
  }, [index]);
  return (
    <div
      ref={cardRef}
      className={cn(
        (width === "large" || width === "full") && "col-span-3 md:col-span-6",
        (width === "small" || width === "half") && "col-span-3 md:col-span-5",
        "relative translate-y-1/4 rounded-lg border border-primary-light-50/10 bg-primary-neutral-50/[0.01] transition-colors duration-300 hover:bg-primary-neutral-50/[0.03]",
      )}
      data-gsap-element="feature-card"
    >
      <Hyperlink
        href={href ?? ""}
        className={cn(
          // width === "large" && "flex flex-col-reverse md:flex-row-reverse",
          // width === "small" && "flex flex-col",
          "flex flex-col",
          "relative z-10",
        )}
      >
        <div
          className={cn(
            (width === "large" || width === "full") && "md:h-[358px] md:p-0",
            (width === "small" || width === "half") && "md:h-[358px]",
            // "overflow-hidden lg:h-[220px] 2xl:h-[280px]",
          )}
        >
          {image && (
            <Image
              src={image.url ?? ""}
              height={image.height ?? 0}
              width={image.width ?? 0}
              alt={image.title ?? "Feature image"}
              className={cn(
                "h-full w-full",
                (width === "large" || width === "full") && "object-contain",
                (width === "small" || width === "half") && "object-contain",
              )}
            />
          )}
        </div>
        <div
          className={cn(
            "p-sm md:p-md",
            // width === "large" && "w-full md:mt-lg md:w-1/2 md:pr-0",
            // width === "small" && "pt-xs md:pt-sm 2xl:pt-md",
            "w-full",
          )}
        >
          {/* {icon && (
            <span className="mb-sm hidden h-lg w-lg rounded-full bg-primary-neutral-50/10 p-2xs md:block">
              <Block_24px />
            </span>
          )} */}

          <h3
            className={cn(
              (width === "large" || width === "full") && typography.heading.secondaryComponentHeading,
              (width === "small" || width === "half") && typography.heading.secondaryComponentHeading,
              "mb-2xs text-primary-dark-800 dark:text-primary-neutral-50/80 md:mb-xs",
            )}
          >
            {heading}
          </h3>
          <p className={cn(typography.body.sm, "font-light text-primary-dark-800 dark:text-primary-neutral-50/70")}>
            {body}
          </p>
        </div>
      </Hyperlink>
      {/* <Hyperlink
        className="absolute bottom-md right-md block h-md w-md rounded-full bg-primary-neutral-50/10 p-2xs"
        href={href ?? ''}
      >
        <ArrowTopRight_12px />
      </Hyperlink> */}
      {bgImage && (
        <Image
          src={bgImage.url ?? ""}
          height={bgImage.height ?? 0}
          width={bgImage.width ?? 0}
          alt={bgImage.title ?? ""}
          className="absolute left-0 top-0 h-full w-full object-cover object-top"
        />
      )}
    </div>
  );
};

export default function HomepageBusinessFeaturesGridV1({
  json,
  assets,
}: {
  json: BusinessFeaturesGridConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  return (
    <MouseInfoProvider>
      <div
        className="grid gap-sm md:grid-cols-11"
        style={{
          background: `
          radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%)
          `,
        }}
      >
        {json.items.map((item, index) => {
          const image = assets?.find((asset) => asset?.title === item.image);
          const bgImage = assets?.find((asset) => asset?.title === item.image + "-bg.svg");
          return <FeatureCard item={item} index={index} key={index} image={image} bgImage={bgImage} />;
        })}
      </div>
    </MouseInfoProvider>
  );
}
