'use client';

import WidgetCard from 'components/Cards/WidgetCard/WidgetCard';
import ColorizedText from "components/ColorizedText";
import ComponentHeading from "components/ComponentHeading/ComponentHeading";
import typography from "components/typography";
import { TemplateWidgetListingFragment } from "contentful/gql/graphql";
import type { FC } from "react";
import { cn } from "utils/functions";

interface WidgetsListingProps {
  widgets: (TemplateWidgetListingFragment | null)[];
}

const WidgetsListing: FC<WidgetsListingProps> = ({ widgets }) => {
  const displayWidgetNames = ["iFrame", "Table", "Chart", "Map"];
  const displayWidgets = widgets.filter((widget) => widget?.title && displayWidgetNames.includes(widget.title));
  return (
    <div>
      <h2 className={cn(typography.heading.sectionHeading, "mb-sm text-center")}>
        <ColorizedText heading={"Explore our {%widgets%}"} />
      </h2>
      <section className="rounded-2xl bg-secondary-light-50 p-sm">
        <h3 className={cn(typography.heading.componentHeading, "mb-xs text-center")}>
          Display, edit, or transform data quickly from any data source
        </h3>
        <div>
          <div className="grid grid-cols-1 gap-sm md:grid-cols-2 lg:grid-cols-3">
            {displayWidgets.map((widget, index) => (widget ? <WidgetCard key={index} widget={widget} /> : null))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WidgetsListing;
