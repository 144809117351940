import React from 'react';
import { IconProps } from './IconProps';

function ArrowTopRight_12px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'ArrowTopRight 12px';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill}>
        <path d="M10.5 1.5l-9 9" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 1.5h6v6" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}

export default ArrowTopRight_12px;
