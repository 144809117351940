"use client";
import typography from "components/typography";
import { cn } from "utils/functions";
import { usePathname } from "next/navigation";
import Icon from "components/Icon";
import Link from "next/link";

const LinkHeading = ({
  heading,
  headingType,
  textAlign,
  iconName,
  headingAdditionalClass,
  iconColorClass,
  href,
}: {
  heading: string;
  headingType?: string | null;
  textAlign: string;
  hasReference?: boolean;
  iconName?: string;
  headingAdditionalClass?: string;
  iconColorClass?: string;
  href: string;
}) => {
  const pathname = usePathname();
  return (
    <>
      <Link href={href} className="group inline-block flex flex-row items-baseline gap-xs transition-all duration-300 hover:ease-in w-fit">
        <div className={cn(iconName ? "relative hidden h-md w-md text-primary-neutral-50 group-hover:block" : "", iconColorClass)}>
          {iconName && <Icon url={`/assets/icons/${iconName}.svg`} />}
        </div>
        {headingType?.toLowerCase() === "h1" ? (
          <h1
            className={cn(
              pathname === "/" || pathname === "/why-appsmith"
                ? typography.heading.homepageHeading
                : typography.heading.pageHeading,
              textAlign,
              headingAdditionalClass
            )}
          >
            {heading}
          </h1>
        ) : headingType?.toLowerCase() === "h2" ? (
          <h2 className={cn(typography.heading.sectionHeading, textAlign, headingAdditionalClass)}>
            {heading}
          </h2>
        ) : (
          <h3 className={cn(typography.heading.componentHeading, textAlign, headingAdditionalClass)}>
            {heading}
          </h3>
        )}
      </Link>
    </>
  );
};

export default LinkHeading;
