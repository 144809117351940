"use client";

import ColorizedText from "components/ColorizedTextV2";
import typography from "components/typography";
import { cn } from "utils/functions";
import { usePathname } from "next/navigation";

const HeadingText = ({
  heading,
  headingType,
  textAlign,
  pinnedContainer,
  hasReference,
}: {
  heading: string;
  pinnedContainer?: any;
  headingType?: string | null;
  textAlign: string;
  hasReference?: boolean;
}) => {
  const pathname = usePathname();
  return (
    <>
      {headingType?.toLowerCase() === "h1" ? (
        <h1
          className={cn(
            pathname === "/" || pathname === "/why-appsmith"
              ? typography.heading.homepageHeading
              : pathname === "/low-code-for-developers"
                ? typography.heading.extraBigHeading
                : typography.heading.pageHeading,
            textAlign,
          )}
        >
          <ColorizedText heading={heading} pinnedContainer={pinnedContainer} />
        </h1>
      ) : headingType?.toLowerCase() === "h2" ? (
        <h2 className={cn(typography.heading.sectionHeading, textAlign, "xl:text-5xl")}>
          <ColorizedText heading={heading} pinnedContainer={pinnedContainer} />
        </h2>
      ) : (
        <h3 className={cn(typography.heading.componentHeading, textAlign)}>
          <ColorizedText heading={heading} pinnedContainer={pinnedContainer} />
        </h3>
      )}
    </>
  );
};

export default HeadingText;
