import Icon from "components/Icon";
import ListItemIconText from "components/ListItem/ListItemIconText";
import typography from "components/typography";
import Link from "next/link";
import { cn } from "utils/functions";

type LowCodeBuyerGuidePointersListProps = {
  elementType: string;
  elementTitle?: string;
  elementLink?: string;
  elementLinkTitle?: string;
  elementLinkIcon?: string;
  content?: {
    icon: string;
    label: string;
  }[];
};

export default function LowCodeBuyerGuidePointersList({ json }: { json: LowCodeBuyerGuidePointersListProps[] }) {
  return (
    <div className="flex flex-col gap-sm py-10">
      {json.map((item, index) => {
        if (item.elementType === "title") {
          return (
            <div key={`LowCodeBuyerGuidePointersList-${index}`} className="flex gap-3">
              <h3 className={cn(typography.heading.cardHeading)}>{item.elementTitle}</h3>
              {item.elementLinkTitle && (
                <Link
                  href={item.elementLink ?? ""}
                  className={cn(
                    typography.heading.cardHeading,
                    "items-cente flex items-center gap-1 text-tertiary-pink-500",
                  )}
                >
                  {item.elementLinkTitle}
                </Link>
              )}
            </div>
          );
        } else if (item.elementType === "pointers-list") {
          return (
            <div key={`LowCodeBuyerGuidePointersList-${index}`} className="grid grid-cols-1 gap-sm md:grid-cols-11">
              {item.content?.map((point, ind) => {
                return (
                  <div
                    key={`LowCodeBuyerGuidePointersList-${index}`}
                    className={cn((ind + 1) % 3 === 0 ? "col-span-5" : "col-span-3")}
                  >
                    <ListItemIconText
                      key={`point-${ind}`}
                      clName="relative flex gap-3xs"
                      icon={point.icon}
                      text={point.label}
                      textClass={cn(typography.body.sm, "font-light")}
                      iconClass="relative top-[4px]"
                    />
                  </div>
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
